import { FileUploader } from "react-drag-drop-files";
import service from "undefined-service-web";
import { Box } from "@mui/material";
import Label from "../Label";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import App from "components";
import { useDispatch } from "react-redux";
import { setLoading, setDialog } from "../../store/reducers/actions";
import { useState } from "react";
const FileUploaderUC = (props) => {
  const dispatch = useDispatch();
  const {
    value,
    onChange,
    name,
    title,
    mx,
    my,
    footerText,
    width,
    height,
    permission,
    boxWidth,
    boxHeight,
  } = props;
  const Img = (props) => {
    const [URI ,setURI] = useState(props.value)
    return (
      <img
        style={{
          width: boxWidth || "100px",
          height: boxHeight || "100px",
          objectFit: "contain",
        }}
        src={URI}
        onError={(e) => {
          // "this.onerror=null;this.src='/images/Defult_Photo.png';"
          setURI("./images/Defult_Photo.png")
          
        }}
        alt="Defult_Photo"
      ></img>
    );
  };
  return (
    <Box mx={mx} my={my}>
      <Label text={title} size={16} fontWeight="bold" />
      <FileUploader
        multple={false}
        disabled={permission == false}
        handleChange={(e) => {
          if (
            e.type.toLowerCase().includes("png") ||
            e.type.toLowerCase().includes("jpeg")
          ) {
            // console.log(e);
            if (
              App.service.isNullOrEmpty(width) &&
              App.service.isNullOrEmpty(height)
            ) {
              service.getBase64(e, (result) => {
                console.log(result);
                onChange({ name: name, value: result });
              });
            } else {
              let _URL = window.URL || window.webkitURL;
              let img = new Image();
              var objectUrl = _URL.createObjectURL(e);
              img.onload = function () {
                if (this.width != width) {
                  dispatch(
                    setDialog({
                      open: true,
                      title: "warning !",
                      description: "width " + width + "px",
                    })
                  );
                } else if (this.height != height) {
                  dispatch(
                    setDialog({
                      open: true,
                      title: "warning !",
                      description: "height " + height + "px",
                    })
                  );
                } else {
                  service.getBase64(e, (result) => {
                    console.log(result);
                    onChange({ name: name, value: result });
                  });
                }
                _URL.revokeObjectURL(objectUrl);
              };
              img.src = objectUrl;
            }
          } else {
          }
        }}
        name="image"
        types={["JPGE", "JPG", "WEBP", "PNG"]}
      >
        <Box className={"dropfile" + (value ? " error" : "")}>
          <Box
            className={
              "dropfile-body " + (service.isNullOrEmpty(value) ? "" : "image")
            }
            style={{
              width: "100px",
              // height: "100px",
            }}
          >
            {service.isNullOrEmpty(value) ? (
              <Box
                style={{
                  border: "1px dashed " + App.color.main,
                  width: boxWidth || "100px",
                  height: boxHeight || "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ fontSize: 35, color: App.color.main }}
                />
              </Box>
            ) : (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt

              <Img boxWidth={boxWidth} value={value} />
            )}
          </Box>
        </Box>
        <App.Label text={footerText} size={14} textAlign="center" />
      </FileUploader>
    </Box>
  );
};
export default FileUploaderUC;
