import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
const icons = {
  AdminPanelSettingsOutlinedIcon,
  AssignmentOutlinedIcon,
  CardGiftcardOutlinedIcon,
};

const dashboard = {
  id: "group-dashboard",
  title: "Menu",
  type: "group",
  children: [
    {
      id: "PrivilegeAndService",
      title: "Privilege & Service",
      type: "item",
      url: "/privilegeandservice/default",
      icon: icons.CardGiftcardOutlinedIcon,
      breadcrumbs: true,
      user :"superadmin"
    },
    {
      id: "PrivilegeAndService",
      title: "Privilege info",
      type: "sub",
      url: "/privilegeandservice/privilege/info/:id?",
      icon: icons.CardGiftcardOutlinedIcon,
      breadcrumbs: true,
      user :"superadmin"
    },

    {
      id: "Report",
      title: "Report",
      type: "collapse",
      icon: icons.AssignmentOutlinedIcon,
      // url: "/report",
      breadcrumbs: true,
      user :"admin",
      children: [
        {
          id: "Report",
          title: "Member Engagement Report",
          type: "item",
          url: "/report",
          icon: icons.AssignmentOutlinedIcon,
          breadcrumbs: true,
          user :"admin",
        },
      ],
    },
  ],
};

export default dashboard;
