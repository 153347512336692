// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import Loading from "components/Loading";
import Dialog from "components/Dialog";
const App = () => {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Dialog />
        <Loading>
          <Routes />
        </Loading>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
