import App from "components";
import React from "react";
import { useDispatch } from "react-redux";
import List from "components/List";
import Enum from "components/Enum";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Columns from "./columns";
const MemberEngagementList = () => {
  const dispatch = useDispatch();
  const [setFooterAction] = useOutletContext();
  const [DataList, setDataList] = React.useState([]);
  const [Data, setData] = React.useState([]);
  const [PrivilegeSelected, setPrivilegeSelected] = React.useState("all");
  const [DataListPrivilege, setDataListPrivilege] = React.useState([]);
  React.useEffect(() => {
    setFooterAction(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Export = () => {
    const item = DataList.map((e) => {
      return {
        Birthday: !App.service.isNullOrEmpty(e.YearOfBirth)
          ? e.YearOfBirth
          : App.service.isNullOrEmpty(e.DateOfBirth)
          ? ""
          : moment(e.DateOfBirth).format("DD MMM YYYY"),
        Member: App.service.isNullOrEmpty(e.FullName) ? "" : e.FullName,
        Age: !App.service.isNullOrEmpty(e.YearOfBirth)
          ? App.service.CalculateAge(new Date(`${e.YearOfBirth - 543}-01-01`))
          : App.service.isNullOrEmpty(e.DateOfBirth)
          ? ""
          : App.service.CalculateAge(new Date(e.DateOfBirth)),
        Gender: App.service.isNullOrEmpty(e.Gender)
          ? ""
          : Enum.Gender.find((f) => f.value === e.Gender)?.text,
        Province: App.service.isNullOrEmpty(e.Province)
          ? ""
          : Enum.Province.find((p) => p.id === e.Province)?.name_th,
        Date: App.service.isNullOrEmpty(e.Date)
          ? ""
          : moment(e.Date).format("DD MMM YYYY"),
        CouponCode: e.CouponCode,
        PhoneNo: e.PhoneNo,
        Status: e.Status,
        CampaignName: e.CampaignName,
        "Is Customer": e.UseProducts,
        "Repeat Customer": e.UsedBefore,
      };
    });
    App.getExportFileBlob({
      Header: [
        "CampaignName",
        "CouponCode",
        "Member",
        "PhoneNo",
        "Birthday",
        "Age",
        "Gender",
        "Province",
        "เคยซื้อผลิตภัณฑ์",
        "เคยเล่นกิจกรรม",
        "Coupon Status",
        "Date",
        // "คุณเคยซื้อผลิตภัณฑ์ชาวดอยหรือไม่",
      ],
      columns: [
        "CampaignName",
        "CouponCode",
        "Member",
        "PhoneNo",
        "Birthday",
        "Age",
        "Gender",
        "Province",
        "Is Customer",
        "Repeat Customer",
        "Status",
        "Date",
        // "คุณเคยซื้อผลิตภัณฑ์ชาวดอยหรือไม่",
      ],
      data: item,
      fileName: "Member Engagement Report",
      SheetName: "Member Engagement Report",
    });
  };
  const GetDataList = async (PrivilegeID = "all") => {
    let _DataList;
    try {
      let path = "/report/memberengagement";
      if (PrivilegeID !== "all") {
        path = `/report/memberengagement?PrivilegeID=${PrivilegeID}`;
      }
      _DataList = await App.service.getHttp(path, dispatch);
      if (_DataList.status) {
        setData(_DataList?.data || []);
        setDataList(_DataList?.data || []);
      }
    } catch (e) {}
  };
  const GetDataListPrivilege = async () => {
    let _DataList;
    try {
      _DataList = await App.service.getHttp("/privilege", dispatch);
      if (_DataList.status) {
        const defaultOption = [{ value: "all", text: "All Privileges" }];
        const privilegeOption =
          _DataList?.data.map((p) => {
            return { value: p.PrivilegeID, text: p.CampaignName };
          }) || [];
        setDataListPrivilege(defaultOption.concat(privilegeOption));
      }
    } catch (e) {}
  };
  const FilterByPrivilege = (e) => {
    setPrivilegeSelected(e.value);
    GetDataList(e.value);
  };
  React.useEffect(() => {
    GetDataListPrivilege();
    GetDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = Columns();
  const [Searchvalue, setSearchvalue] = React.useState("");
  const [StartDate, setStartDate] = React.useState(null);
  const [EndDate, setEndDate] = React.useState(null);
  const onSearch = ({ name, value }) => {
    if (name === "Search") {
      setSearchvalue(value);
    } else if (name === "StartDate") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };
  const DisplayReport = () => {
    // if (!App.service.isNullOrEmpty(Searchvalue)) {
    let _Data = Data;
    const _Searchvalue = Searchvalue.toLowerCase().trim();
    const getDate = (date) => {
      let _date = moment(date).format("yyyy-MM-DD");

      return new Date(_date.split("T")[0]);
    };
    if (
      !App.service.isNullOrEmpty(StartDate) &&
      App.service.isNullOrEmpty(EndDate)
    ) {
      _Data = _Data.filter((e) => {
        if (getDate(e.Date) >= getDate(StartDate.toDate()) || e.Date === "-") {
          return e;
        }
      });
    } else if (
      App.service.isNullOrEmpty(StartDate) &&
      !App.service.isNullOrEmpty(EndDate)
    ) {
      _Data = _Data.filter((e) => {
        if (getDate(e.Date) <= getDate(EndDate.toDate()) || e.Date === "-") {
          return e;
        }
      });
    } else if (
      !App.service.isNullOrEmpty(StartDate) &&
      !App.service.isNullOrEmpty(EndDate)
    ) {
      _Data = _Data.filter((e) => {
        if (
          (getDate(e.Date) >= getDate(StartDate.toDate()) &&
            getDate(e.Date) <= getDate(EndDate.toDate())) ||
          e.Date === "-"
        ) {
          return e;
        }
      });
    }
    const getString = (e) =>
      App.service.isNullOrEmpty(_Searchvalue)
        ? true
        : e.toString().toLowerCase().trim().includes(_Searchvalue);
    setDataList(
      App.service.lodash.filter([..._Data], (e) => {
        if (
          getString(e?.CouponCode || "") ||
          getString(e?.FullName || "") ||
          getString(e?.PhoneNo || "") ||
          getString(e?.UseProducts || "") ||
          getString(
            App.service.isNullOrEmpty(e.DateOfBirth)
              ? ""
              : moment(e.DateOfBirth).format("DD MMM YYYY")
          ) ||
          getString(
            App.service.isNullOrEmpty(e.DateOfBirth)
              ? ""
              : App.service.CalculateAge(new Date(e.DateOfBirth))
          ) ||
          getString(
            App.service.isNullOrEmpty(e?.Gender)
              ? ""
              : Enum.Gender.find((g) => g.value === e?.Gender).text
          ) ||
          getString(
            App.service.isNullOrEmpty(e?.Province)
              ? ""
              : Enum.Province.find((p) => p.id === e?.Province)?.name_th
          ) ||
          getString(e?.Status || "") ||
          getString(
            App.service.isNullOrEmpty(e.Date)
              ? ""
              : moment(e.Date).format("DD MMM YYYY") || ""
          )
        ) {
          return e;
        }
      })
    );
    // }
    //  else {
    //   setDataList(Data);
    // }
  };

  return (
    <List
      title="Member"
      name="memberengagement"
      columns={columns}
      data={DataList}
      Export={() => Export()}
      IsSelect={false}
      IsDelete={false}
      IsAdd={false}
      onSearch={onSearch}
      Searchvalue={Searchvalue}
      IsSearchDate={true}
      StartDate={StartDate}
      EndDate={EndDate}
      DisplayReport={DisplayReport}
      IsFilter={true}
      Filter={PrivilegeSelected}
      FilterOption={DataListPrivilege}
      onFilter={FilterByPrivilege}
    />
  );
};

export default MemberEngagementList;
