import { Box } from "@mui/material";
import Color from "../color";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDialog } from "../../store/reducers/actions";
import FindInPageIcon from "@mui/icons-material/FindInPage";
const Action = (props) => {
  const { id, isDelete, isEdit, IsView, name, Delete, permission } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isEdit !== false ? (
          <EditOutlinedIcon
            style={{ fontSize: 16, cursor: "pointer", color: "#004FD8" }}
            onClick={() => {
              navigate("/" + name + "/info/" + id);
            }}
          />
        ) : null}
        {isDelete !== false && isEdit !== false ? (
          <Box
            ml={1}
            mr={1}
            style={{ borderLeft: "1px solid #004FD8", height: "16px" }}
          />
        ) : null}
        {IsView === true ? (
          <Box
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/" + name + "/info/" + id);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M18.3928 15.9545H17.8918L17.1923 15.255C17.9684 14.2078 18.3891 12.9398 18.3928 11.6364C18.3928 8.29409 15.6896 5.59091 12.3473 5.59091C11.0518 5.59091 9.75639 6.02273 8.71139 6.8C6.04276 8.80364 5.49866 12.595 7.5023 15.2636C9.50594 17.9323 13.2973 18.4764 15.9659 16.4727L16.6655 17.1723V17.6818L20.9837 22L22.7109 20.2727L18.3928 15.9545ZM12.3473 15.9545C9.96367 15.9545 8.02912 14.02 8.02912 11.6364C8.02912 9.25273 9.96367 7.31818 12.3473 7.31818C14.7309 7.31818 16.6655 9.25273 16.6655 11.6364C16.6655 14.02 14.7309 15.9545 12.3473 15.9545ZM5.43821 7.31818L3.71094 9.04545V3H9.75639L8.02912 4.72727H5.43821V7.31818ZM20.9837 3V9.04545L19.2564 7.31818V4.72727H16.6655L14.9382 3H20.9837ZM8.02912 18.5455L9.75639 20.2727H3.71094V14.2273L5.43821 15.9545V18.5455H8.02912Z"
                fill="#004FD8"
              />
            </svg>
          </Box>
        ) : // <FindInPageIcon
        //   style={{ fontSize: 16, cursor: "pointer" }}
        //   onClick={() => {
        //     navigate("/" + name + "/info/" + id);
        //   }}
        // />
        null}
        {isDelete !== false ? (
          <DeleteOutlineOutlinedIcon
            style={{
              fontSize: 16,
              cursor: "pointer",
              color: permission == false ? "#DDD" : "#004FD8",
            }}
            onClick={() => {
              if (permission != false) {
                dispatch(
                  setDialog({
                    open: true,
                    title: "Confirm Delete !",
                    description: "Confirm Delete !",
                    Callback: () => {
                      Delete(id);
                    },
                  })
                );
              }
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};
export default Action;
