import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, Chip, Box } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/Logo";
import App from "components";
// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      pl={5}
      pr={5}
      pt={5}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
      }}
    >
      <Box>
      <svg xmlns="http://www.w3.org/2000/svg" width="120" height="48" viewBox="0 0 120 48" fill="none">
<g clip-path="url(#clip0_352_6383)">
<g clip-path="url(#clip1_352_6383)">
<path d="M14.9639 46.1525C13.5851 47.3887 11.8191 48.0068 9.65924 48.0068C7.21408 48.0068 5.23757 47.1849 3.72971 45.548C2.24223 43.884 1.50189 41.7988 1.50189 39.2789C1.50189 36.759 2.25582 34.8029 3.76367 33.1184C5.27153 31.4068 7.23445 30.5578 9.65245 30.5578C11.5475 30.5578 13.2251 31.1012 14.6718 32.1947C16.2884 33.4512 17.0898 35.0202 17.0898 36.8949H12.8176C12.7429 36.0662 12.4101 35.421 11.8056 34.9727C11.2011 34.5244 10.4471 34.2935 9.54378 34.2935C8.26006 34.2935 7.30237 34.7689 6.6775 35.7198C6.04583 36.6707 5.73339 37.8593 5.73339 39.2789C5.73339 40.6985 6.03225 41.8192 6.62316 42.6953C7.30917 43.7413 8.28044 44.2643 9.54378 44.2643C11.5135 44.2643 12.607 43.2319 12.8176 41.1671H17.0898C17.0898 43.2591 16.3767 44.9164 14.9503 46.1525H14.9639Z" fill="white"/>
<path d="M29.0644 47.6808V40.7732H23.5084V47.6808H19.2701V30.911H23.5084V37.0375H29.0644V30.9517H33.3027V47.6875H29.0644V47.6808Z" fill="white"/>
<path d="M46.5813 47.6808L45.5828 44.6922H39.8842L38.8518 47.6808H34.5456L40.5974 30.911H44.9036L50.9893 47.6808H46.5745H46.5813ZM42.7709 35.5364L40.8827 41.4116H44.5504L42.7709 35.5364Z" fill="white"/>
<path d="M65.5925 45.4733C64.1186 47.1578 62.1353 48 59.6425 48C57.1498 48 55.2413 47.1917 53.7674 45.582C52.3206 43.9655 51.5939 41.8667 51.5939 39.2789C51.5939 36.6911 52.2935 34.735 53.6926 33.1184C55.1869 31.4068 57.1702 30.5578 59.6358 30.5578C60.8448 30.5578 61.9451 30.7616 62.9299 31.1623C63.9148 31.563 64.791 32.2083 65.5449 33.0845C66.9441 34.701 67.6437 36.7658 67.6437 39.2789C67.6437 41.792 66.9577 43.884 65.5789 45.4733H65.5925ZM62.5632 35.8625C61.9179 34.8165 60.9398 34.2935 59.629 34.2935C58.4132 34.2935 57.4623 34.7825 56.7695 35.7538C56.1514 36.7047 55.839 37.8797 55.839 39.2789C55.839 40.6781 56.1242 41.8192 56.6948 42.6953C57.34 43.7413 58.3181 44.2643 59.629 44.2643C60.8719 44.2643 61.85 43.7549 62.5632 42.7361C63.1337 41.8803 63.419 40.7324 63.419 39.2857C63.419 37.9341 63.1337 36.793 62.5632 35.8692V35.8625Z" fill="white"/>
<path d="M92.7678 45.5073C92.0818 46.2205 91.2395 46.757 90.2479 47.1238C89.2494 47.4906 88.1491 47.674 86.9401 47.674H79.9442V30.9042H86.9401C88.1491 30.9042 89.2426 31.0944 90.2275 31.4747C91.2124 31.8551 92.0478 32.3985 92.7474 33.1116C93.447 33.8248 93.9768 34.6942 94.3435 35.7266C94.7103 36.759 94.8937 37.9137 94.8937 39.1974C94.8937 41.8803 94.1805 43.9791 92.761 45.5005L92.7678 45.5073ZM86.1794 34.6467H84.1825V43.9383H86.1794C87.7484 43.9383 88.8827 43.5783 89.5958 42.8652C90.309 42.152 90.6622 40.9362 90.6622 39.2178C90.6622 36.1681 89.1679 34.6399 86.1794 34.6399V34.6467Z" fill="white"/>
<path d="M110.597 45.4733C109.123 47.1578 107.147 48 104.654 48C102.161 48 100.253 47.1917 98.7788 45.582C97.3321 43.9655 96.6053 41.8667 96.6053 39.2789C96.6053 36.6911 97.3049 34.735 98.7041 33.1184C100.198 31.4068 102.182 30.5578 104.647 30.5578C105.856 30.5578 106.957 30.7616 107.941 31.1623C108.926 31.563 109.796 32.2083 110.556 33.0845C111.956 34.701 112.655 36.7658 112.655 39.2789C112.655 41.792 111.969 43.884 110.59 45.4733H110.597ZM107.568 35.8625C106.923 34.8165 105.945 34.2935 104.634 34.2935C103.418 34.2935 102.467 34.7825 101.774 35.7538C101.156 36.7047 100.844 37.8797 100.844 39.2789C100.844 40.6781 101.129 41.8192 101.706 42.6953C102.351 43.7413 103.33 44.2643 104.64 44.2643C105.883 44.2643 106.861 43.7549 107.575 42.7361C108.145 41.8803 108.43 40.7324 108.43 39.2857C108.43 37.9341 108.145 36.793 107.575 35.8692L107.568 35.8625Z" fill="white"/>
<path d="M114.978 47.6808V30.911H119.216V47.6808H114.978Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.005 23.2223C114.251 22.7536 112.628 21.8571 108.193 20.0707C102.216 17.6663 98.935 17.449 97.6173 17.2995C96.3065 17.1569 96.5985 16.7154 94.6288 15.5472C92.6591 14.3789 92.2176 15.5472 90.7641 15.8392C89.3038 16.1313 89.5279 15.2551 86.0979 13.8695C82.6679 12.4839 79.6793 7.30832 77.4923 6.7242C75.3052 6.14008 77.1255 7.45096 74.1369 9.12861C71.1484 10.8063 70.639 13.8695 69.036 13.2854C67.4331 12.7013 63.5684 10.0048 61.816 8.32714C60.0636 6.64949 57.3672 4.38771 53.5772 1.18182C49.7871 -2.02407 50.806 1.98329 47.6001 5.12126C44.3874 8.25243 45.413 8.25243 44.2448 9.5701C43.0765 10.881 43.878 12.7081 43.2939 12.3413C42.7097 11.9745 41.7656 11.9066 40.2306 11.6145C38.6956 11.3225 35.1229 4.97862 33.7373 3.81038C32.3517 2.64213 32.1344 3.51831 31.1156 4.83599C30.0968 6.14687 28.6364 7.45775 28.6364 7.45775C25.0638 7.38304 23.4608 11.4651 20.5402 12.0492C17.6196 12.6334 17.6943 14.3857 15.942 15.4793C14.1896 16.5728 14.339 15.6966 12.5866 16.1381C11.0924 16.5117 6.35825 21.7144 5.01341 23.2291H1.33887V24.0713H118.856V23.2291H114.998L115.005 23.2223Z" fill="#006838"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9352 22.8759C15.9352 22.8759 18.8422 19.4527 20.6082 19.2421C22.3741 19.0316 19.6844 22.1695 22.4896 21.2322C25.2947 20.3017 26.755 17.8565 30.8032 18.169C34.8513 18.4814 33.031 19.2964 31.6793 19.9213C30.3277 20.5462 33.9343 20.6073 35.1773 20.5054C36.4203 20.4036 32.9631 22.8351 26.429 22.8351L15.942 22.8691L15.9352 22.8759Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.044 8.65315C29.044 8.65315 31.2243 8.86371 31.8491 8.02828C32.474 7.19964 31.4348 5.01936 32.7865 6.47288C34.1381 7.9264 31.0205 1.49425 35.6935 8.02828C40.3665 14.5691 39.4292 12.4907 40.577 12.905C41.7181 13.3194 42.4449 13.6318 42.6554 14.5623C42.8592 15.4996 38.8111 11.8658 39.2254 14.4604C39.6397 17.055 36.3184 11.4515 36.3184 12.0764C36.3184 12.7013 34.0362 13.5299 33.0989 14.2567C32.1616 14.9834 31.2311 11.9745 33.0989 11.3496C34.9667 10.7248 31.5435 10.8334 31.9578 9.68556C32.3721 8.54448 30.8167 12.8031 27.183 13.1088C23.5492 13.4212 28.0116 12.4839 29.0508 10.8266C30.09 9.16256 29.4651 9.475 29.0508 8.64636L29.044 8.65315Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M54.2768 2.71006C54.2768 2.71006 52.0421 0.570534 52.3342 2.22781C52.6263 3.8783 52.5244 2.22782 52.1372 5.14164C51.7501 8.05547 50.7381 7.1589 51.0709 9.61766C51.3154 11.4447 48.3676 11.601 48.4287 12.8983C48.4967 14.3382 52.5448 10.7519 52.5448 14.1548C52.5448 17.5577 51.0097 18.5289 52.2731 18.2369C53.5364 17.9448 52.341 10.7791 55.4518 11.6553C58.5626 12.5315 51.9946 19.2421 55.2616 20.0028C64.3427 22.1084 52.4021 19.3304 56.3823 16.9124C58.9905 15.3298 56.2261 11.8523 57.1974 10.881C58.1686 9.90972 57.8766 10.9829 59.432 12.7285C60.9874 14.4808 61.7685 14.3789 63.3239 14.3789C64.8793 14.3789 64.6416 13.3058 66 13.7948C67.3584 14.2838 67.895 15.5132 69.8375 15.9004C71.7801 16.2875 67.6029 13.7948 65.1713 12.7285C62.7398 11.6553 61.7685 9.51577 62.2575 11.9474C62.7398 14.3789 60.7972 9.80784 59.0448 8.34753C57.2925 6.88722 57.5845 5.72576 56.2261 5.33182C54.8677 4.94467 54.0866 5.03976 53.8964 4.26546C53.7062 3.48436 54.2835 2.71006 54.2835 2.71006H54.2768Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.6946 12.7964C43.6946 12.7964 46.8869 16.4166 47.274 18.3659C47.6612 20.3085 47.7631 21.9793 48.9313 22.0812C50.1607 22.1831 46.4454 19.0519 49.237 19.5002C51.6685 19.8873 50.8671 21.9657 52.8164 22.5499C56.9392 23.786 63.4461 23.0321 63.1744 23.0593C63.1744 23.0593 56.3959 22.7944 54.3514 22.1084C52.307 21.4292 52.4293 19.8262 50.4867 19.2421C48.5442 18.658 49.0332 19.3372 48.157 18.461C47.2808 17.5848 46.0379 14.2567 43.7014 12.7964H43.6946Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M78.0968 7.57321C77.9541 6.9755 77.4176 7.69547 77.2206 9.71273C76.7383 14.7661 78.5858 10.4938 78.973 11.4651C79.3601 12.4364 80.8204 14.2838 80.8204 14.7729C80.8204 15.2619 78.5858 16.4234 80.6302 17.5916C82.6679 18.7599 80.9223 16.2264 83.1569 16.9124C85.3983 17.5916 87.2729 18.6512 89.7724 18.2708C96.191 17.2995 91.6199 18.7599 88.0201 16.5184C84.427 14.2838 85.2964 15.2551 83.938 13.5978C82.5796 11.9473 78.6877 9.998 78.1036 7.57321H78.0968Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70.1704 22.9098C70.1704 22.9098 51.0641 17.4422 62.0062 18.1689C67.8746 18.5629 70.1704 21.6737 77.4651 22.1084C84.7599 22.5431 89.2766 22.2578 91.3211 21.959C91.3211 21.959 84.9229 21.0488 87.3952 20.7635C89.8743 20.4715 96.1774 21.2729 96.1774 21.2729C96.1774 21.2729 93.1346 21.2186 93.1346 21.9182C93.1346 22.5567 101.53 22.4004 101.53 22.4004C101.53 22.4004 95.4031 20.0639 101.095 19.4798C106.78 18.8957 97.7532 20.6956 106.053 21.3748C109.497 21.6601 113.783 23.1136 113.783 23.1136L70.1772 22.9031L70.1704 22.9098Z" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_352_6383">
<rect width="119" height="48" fill="white" transform="translate(0.5)"/>
</clipPath>
<clipPath id="clip1_352_6383">
<rect width="117.877" height="48" fill="white" transform="translate(1.33887)"/>
</clipPath>
</defs>
</svg>
      </Box>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
