import PropTypes from "prop-types";
import React, { forwardRef, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import Highlighter from "./third-party/Highlighter";
import Label from "./Label";
// import App from "App";
// header style
const headerSX = {
  p: 2.5,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight,
      Tab,
      isTab,
      toolBar,
      Impost,
      isImpost,
      ImpostName,
      isCoponCode,
      ActionCoponCode,
      IsPadding,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

    return (
      <Box>
        {isTab === true ? <Tab></Tab> : null}
        <Card
          elevation={elevation || 0}
          ref={ref}
          {...others}
          className="info-Card"
          sx={{
            ...sx,
            border: border ? "1px solid" : "none",
            borderRadius: 2,
            borderColor:
              theme.palette.mode === "dark"
                ? theme.palette.divider
                : theme.palette.grey.A800,
            boxShadow:
              boxShadow && (!border || theme.palette.mode === "dark")
                ? shadow || theme.customShadows.z1
                : "inherit",
            ":hover": {
              boxShadow: boxShadow
                ? shadow || theme.customShadows.z1
                : "inherit",
            },
            "& pre": {
              m: 0,
              p: "16px !important",
              fontFamily: theme.typography.fontFamily,
              fontSize: "0.75rem",
            },
            "& .MuiCardContent-root": {
              padding: IsPadding || "20px",
            },
          }}
        >
          <Box style={{ justifyContent: "space-between", display: "flex" }}>
            <Box p={2}>
              <Label text={title} size={32} color="#000" />
            </Box>
            {darkTitle && title && (
              <CardHeader
                sx={headerSX}
                title={<Label text={title} size={16} color="#FFF" />}
                action={secondary}
              />
            )}
            {toolBar !== false ? (
              <CardHeader
                sx={headerSX}
                title={
                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    {isCoponCode === true ? (
                      <Box
                        style={{
                          padding: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Box pr={1}>
                          <img
                            alt={"logo"}
                            src={"/images/xlsx.png"}
                            style={{
                              // width: "30%",
                              maxWidth: "30px",
                              height: "auto",
                            }}
                          />
                        </Box>
                        <Label
                          text="Download Coupon"
                          color="blue"
                          onClick={ActionCoponCode}
                        />
                      </Box>
                    ) : null}
                    {isImpost === true ? (
                      <Box style={{ padding: 5 }}>
                        <SheetJSApp Impost={Impost} text={ImpostName} />
                      </Box>
                    ) : null}
                    {isImpost === true ? (
                      <Box style={{ padding: 5 }}>
                        <Button
                          disableElevation
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(
                              process.env.REACT_APP_KEY_URLAPI +
                                "privilege/Template/CouponTemplate"
                            );
                          }}
                          sx={{
                            height: 50,
                            borderRadius: "10px",
                            "&.MuiButton-root:hover": {
                              backgroundColor: "#1E1E1E",
                            },
                            "&.MuiButton-root": {
                              backgroundColor: "#1E1E1E",
                            },
                          }}
                        >
                          <Label
                            text="Coupon Template"
                            size={18}
                            color="#FFF"
                          />
                        </Button>
                      </Box>
                    ) : null}
                  </Box>
                }
                action={secondary}
              />
            ) : null}
          </Box>

          {/* content & header divider */}
          {title && divider && <Divider />}

          {/* card content */}
          {content && <CardContent sx={contentSX}>{children}</CardContent>}
          {!content && children}

          {/* card footer - clipboard & highlighter  */}
          {codeHighlight && (
            <>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Highlighter codeHighlight={codeHighlight} main>
                {children}
              </Highlighter>
            </>
          )}
        </Card>
      </Box>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
};
class SheetJSApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
    };
    this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }
  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });
      this.props.Impost(data.slice(1));
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }
  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <div className="row">
          <div className="col-xs-12">
            <DataInput handleFile={this.handleFile} text={this.props.text} />
          </div>
        </div>
      </DragDropFile>
    );
  }
}
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    // const ref = useRef();
    return (
      <ButtonFile handleChange={this.handleChange} text={this.props.text} />
    );
  }
}

const ButtonFile = ({ handleChange, text }) => {
  const uploadInputRef = useRef(null);
  return (
    <label htmlFor="fileimport">
      <input
        ref={uploadInputRef}
        type="file"
        className="form-control"
        id="fileimport"
        accept={SheetJSFT}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Button
        disableElevation
        fullWidth
        size="large"
        // type="file"
        variant="contained"
        color="primary"
        onClick={(e) => {
          uploadInputRef.current.click();
        }}
        sx={{
          height: 50,
          borderRadius: "10px",
          "&.MuiButton-root:hover": {
            backgroundColor: "#1E1E1E",
          },
          "&.MuiButton-root": {
            backgroundColor: "#1E1E1E",
          },
        }}
        htmlFor="fileimport"
      >
        <VerticalAlignTopIcon fontSize="12" htmlFor="file" />
        <Label text={text} size={18} color="#FFF" htmlFor="fileimport" />
      </Button>
    </label>
  );
};

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

export default MainCard;
