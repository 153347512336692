import App from "components";
import React from "react";
// project import
import { Grid, Box, Button } from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LanguageIcon from "@mui/icons-material/Language";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/material/styles";
import Slider from "./slider/slider";
import CircularProgress from "@mui/material/CircularProgress";
const BoxReactQuill = styled(Box)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .ql-toolbar": {
    display: "none",
  },
  "& .ql-container.ql-snow": {
    border: "none",
  },
}));
const View = ({ privileges }) => {
  const getdate = () => {
    let text =
      App.service.isNullOrEmpty(privileges?.StartDate) &&
      App.service.isNullOrEmpty(privileges?.ExpirationDate)
        ? "No Expiration Date"
        : moment(privileges?.StartDate).format("D MMM yyyy") +
          (App.service.isNullOrEmpty(privileges?.ExpirationDate)
            ? ""
            : "-" + moment(privileges?.ExpirationDate).format("D MMM yyyy"));
    return text;
  };
  const image = [
    privileges.image0 || null,
    privileges.image1,
    privileges.image2,
    privileges.image3,
    privileges.image4,
  ];
  return (
    <Box
      sx={{
        position: "absolute",
        // border: "1px solid red",
        width: 340,
        height: 600,
        top: 0,
        right: 0,
        // padding: 0.2,
        backgroundColor: "#DDD",
        borderRadius: 1,
        boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box
        sx={{
          position: "relative",
          // border: "1px solid red",
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            width: "100%",
            top: 0,
            right: 0,
          }}
        >
          <Box sx={{ width: "100%" }}>
            {image.length > 0 ? (
              <Slider loop={true} showNav={true} selected={0}>
                {image ? (
                  image.map((img, i) => {
                    return (
                      <Box key={i}>
                        <img
                          alt={"privileges-" + i}
                          src={img}
                          style={{
                            display: "block",
                            width: "100%",
                            aspectRatio: 1 / 1,
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Box></Box>
                )}
              </Slider>
            ) : (
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  aspectRatio: 1 / 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: App.color.main }} />
              </Box>
            )}
          </Box>
          <Box
            style={{
              backgroundColor: "#FFF",
              padding: 20,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  height: "42px",
                  background: "#E4DDD2",
                  borderRadius: "26px",
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <App.Label text={getdate()} size={18} color={"#000"} />
              </Box>
              <Box>
                <ShareIcon style={{ color: "#338660" }} />
              </Box>
            </Box>
            <Box mt={2}>
              <App.Label
                text={privileges?.CampaignName}
                size={24}
                color={App.color.main}
                fontWeight="500"
              />
            </Box>
            <Box mt={2}>
              <App.Label
                text={privileges?.Description}
                size={18}
                color={App.color.gray}
                fontWeight="400"
              />
            </Box>

            <Box
              mt={2}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  width: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LanguageIcon style={{ color: "#0D2853" }} />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "calc(100% - 20px)",
                  }}
                  pl={1}
                  onClick={() => {
                    window.open("https://" + privileges?.Website);
                  }}
                >
                  <App.Label
                    text={privileges?.Website}
                    size={18}
                    color={App.color.gray}
                    numberOfLine={1}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  width: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MarkEmailReadIcon style={{ color: "#0D2853" }} />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "calc(100% - 20px)",
                  }}
                  pl={1}
                  onClick={() => {
                    window.open("mailto:" + privileges?.Email);
                  }}
                >
                  <App.Label
                    text={privileges?.Email}
                    size={18}
                    color={App.color.gray}
                    numberOfLine={1}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  width: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocalPhoneIcon style={{ color: "#0D2853" }} />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "calc(100% - 20px)",
                  }}
                  pl={1}
                  onClick={() => {
                    window.open("tel:" + privileges?.PhoneNo);
                  }}
                >
                  <App.Label
                    text={privileges?.PhoneNo}
                    size={18}
                    color={App.color.gray}
                    numberOfLine={1}
                  />
                </Box>
              </Box>
            </Box>

            {privileges?.IsSpecialMessage ? (
              <Box
                mt={1}
                sx={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    background: "#338660",
                    padding: "10px 16px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    mt={1}
                    mb={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: App.color.black,
                    }}
                  >
                    <App.Label
                      text="Privileges for Consumers"
                      size={24}
                      color={"#FFF"}
                    />
                    <Box
                      pl={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="22"
                        viewBox="0 0 19 22"
                        fill="none"
                      >
                        <path
                          d="M9.5 0L18.5 4V10C18.5 15.55 14.66 20.74 9.5 22C4.34 20.74 0.5 15.55 0.5 10V4L9.5 0ZM13.5 13H5.5V14.5C5.5 14.77 5.69 14.96 5.97 15H12.93C13.24 15 13.45 14.84 13.5 14.59V13ZM14.5 7L11.83 9.67L9.5 7.34L7.17 9.67L4.5 7L5.5 12H13.5L14.5 7Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    backgroundColor: "#FFF",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <App.Label
                    text={privileges?.SpecialMessage}
                    size={18}
                    color={App.color.gray}
                  />
                </Box>
              </Box>
            ) : null}
            <Box mt={1}>
              <BoxReactQuill>
                <ReactQuill readOnly={true} value={privileges?.Condition} />
              </BoxReactQuill>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default View;
