import App from "components";
import React from "react";
// project import
import { Grid, Box } from "@mui/material";
import View from "./view";
const CouponDetail = (props) => {
  const { form, onChange, category, ID } = props;
  return (
    <Box sx={{ position: "relative" }}>
      <Box mb={2}>
        <App.Label size={16} text="COUPON INFORMATION" fontWeight="bold" />
      </Box>
      <Grid container spacing={2}>
        {/* <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Select
            title="Category"
            name="Category"
            onChange={onChange}
            value={form.Category}
            data={category}
            helperText={form.error.Category}
            disabled={!App.service.isNullOrEmpty(ID)}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Coupon Title"
            name="CouponTitle"
            onChange={onChange}
            value={form.CouponTitle}
            maxlength={500}
            helperText={form.error.CouponTitle}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={3}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Switch
            name="IsSpecialMessage"
            value={form.IsSpecialMessage}
            onChange={onChange}
            title={"Special Message"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={-4}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Special Message"
            name="SpecialMessage"
            isTitle={false}
            onChange={onChange}
            value={form.SpecialMessage}
            maxlength={250}
            helperText={form.error.SpecialMessage}
            disabled={!form.IsSpecialMessage}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.ReactQuill
            title="Condition & Description"
            name="Condition"
            onChange={onChange}
            value={form.Condition}
            maxlength={500}
            helperText={form.error.Condition}
            multiline={true}
            maxRows={3}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }} display={"flex"}>
          <App.FileUploader
            mx={1}
            title="Cover Image"
            name="image0"
            value={form.image0}
            onChange={onChange}
          />
          <App.FileUploader
            mx={1}
            title="Image 1"
            name="image1"
            value={form.image1}
            onChange={onChange}
          />
          <App.FileUploader
            mx={1}
            title="Image 2"
            name="image2"
            value={form.image2}
            onChange={onChange}
          />
          <App.FileUploader
            mx={1}
            title="Image 3"
            name="image3"
            value={form.image3}
            onChange={onChange}
          />
          {/* </Grid> */}
          <App.FileUploader
            mx={1}
            title="Image 4"
            name="image4"
            value={form.image4}
            onChange={onChange}
          />
          {/* </Box> */}
        </Grid>
      </Grid>
      {/* <App.Label size={16} text="ACCTION BUTTON" fontWeight="bold" />
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Select
            title="Button Type"
            name="ButtonType"
            onChange={onChange}
            value={form.ButtonType}
            data={App.Enum.ButtonType}
            // data={[
            //   { value: 3, text: "Show Information" },
            //   { value: 1, text: "Call" },
            //   { value: 2, text: "Email" },
            //   { value: 4, text: "Link to Partner" },
            // ]}
            helperText={form.error.ButtonType}
            disabled={!App.service.isNullOrEmpty(ID)}
          />
        </Grid>
      </Grid> */}

      <View privileges={form} />
      {form.ButtonType > 0 ? (
        <Grid container spacing={2} mt={1}>
          <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
            <App.Input
              title={
                form.ButtonType === 1
                  ? "Call"
                  : form.ButtonType === 2
                  ? "Email"
                  : form.ButtonType === 3
                  ? "Show Information"
                  : "Link to Partner"
              }
              name="ButtonInput"
              onChange={onChange}
              value={form.ButtonInput}
              maxlength={500}
              helperText={form.error.ButtonInput}
              // disabled={!App.service.isNullOrEmpty(ID)}
            />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};
export default CouponDetail;
