import * as React from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Label from "../Label";
import color from "../color";
import FormHelperText from "@mui/material/FormHelperText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: "20px",
    padding: "9px 5px",
    boxSizing: "content-box",

    borderRadius: 4,
    position: "relative",
    color: color.main,
    border: "1px solid #D6D6D8",
    backgroundColor: "#FFF",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      borderColor: "#161e44",
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    backgroundColor: "#ededee !important",
    border: "1px solid #ededee",
  },
  "&.Mui-error": {
    "& div": {
      border: "1px solid red",
      "&::placeholder": {
        color: "red",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "red",
    },
  },
}));
const BasicSelect = (props) => {
  const {
    title,
    name,
    data,
    value,
    onChange,
    disabled,
    helperText,
    isTitle,
    multiple,
  } = props;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    onChange({ name: name, value: event.target.value });
  };
  const handleDelete = (id) => {
    onChange({
      name: name,
      value: value.filter((e) => {
        if (e != id) {
          return e;
        }
      }),
    });
  };
  // console.log(value);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        {isTitle != false ? (
          <InputLabel shrink htmlFor={name} style={{ transform: "none" }}>
            <Label
              text={isTitle !== false ? title : ""}
              size={18}
              fontWeight="bold"
              color={
                helperText != null && helperText !== undefined
                  ? "#ED4040"
                  : "#161E44"
              }
            />
          </InputLabel>
        ) : null}
        <Select
          id={name}
          value={value}
          IconComponent={ExpandMoreIcon}
          onChange={handleChange}
          input={<BootstrapInput placeholder={title} />}
          disabled={disabled || false}
          error={helperText != null && helperText != undefined}
          placeholder={title}
          multiple={multiple || false}
          renderValue={
            multiple
              ? (selected) => {
                  console.log(selected);
                  let _selected = [];
                  data.map((e, i) => {
                    if (selected.includes(e.value)) {
                      _selected.push({ text: e.text, value: e.value });
                    }
                  });
                  // return _selected.join(", ");
                  return (
                    <Box
                      // key={i}
                      sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                    >
                      {_selected.map(({ text, value }) => (
                        <Chip
                          key={value}
                          label={text}
                          style={{ height: 20, zIndex: 999 }}
                          onDelete={() => {
                            if (!disabled) {
                              handleDelete(value);
                            }
                          }}
                          // disabled ={disabled}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        ></Chip>
                      ))}
                    </Box>
                  );
                }
              : null
          }
        >
          {[...data].map((e, i) => {
            return (
              <MenuItem key={i} value={e.value}>
                {multiple ? (
                  <Checkbox checked={value.indexOf(e.value) > -1} />
                ) : null}
                {e.text}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText id="component-error-text" style={{ color: "red" }}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
export default BasicSelect;
BasicSelect.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  // data: [{ value: PropTypes.number, text: PropTypes.string }],
};
