/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import service from "undefined-service-web";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../store/reducers/actions";
const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const checkAuth = () => {
    let token = localStorage.getItem("token");
    if (service.isNullOrEmpty(authUser) && service.isNullOrEmpty(token)) {
      navigate("login", { replace: true });
      localStorage.removeItem("Username");
    } else if (!service.isNullOrEmpty(token)) {
      dispatch(setAuthUser(token));
      if (window.location.pathname === "/") {
        navigate("/report");
      }
    }
  };
  useEffect(() => {
    checkAuth();
  }, [authUser, navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
