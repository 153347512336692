import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Label from "../Label";
export default function RowRadioButtonsGroup(props) {
  const { title, name, data, onChange, value, disabled, row } = props;
  const handleChange = (event, value) => {
    console.log(event.target.value);
    onChange({ name: name, value: parseInt(value) });
  };
  return (
    <FormControl>
      <FormLabel style={{ transform: "none" }}>
        <Label text={title} size={16} fontWeight="bold" />
      </FormLabel>
      <RadioGroup
        row={row === false ? false : true}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        onChange={handleChange}
        value={value}
        disabled={disabled || false}
      >
        {[...data].map((e, i) => {
          return (
            <FormControlLabel
              key={i}
              value={e.value}
              control={<Radio />}
              label={e.text}
              disabled={disabled || false}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
