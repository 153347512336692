import App from "components";
import React from "react";
// project import
import { Grid, Box } from "@mui/material";
const CampaignDetail = (props) => {
  const { form, onChange, partner, ID } = props;
  return (
    <>
      <Grid container spacing={2} >
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Campaign Code"
            name="CampaignCode"
            onChange={onChange}
            value={form.CampaignCode}
            maxlength={500}
            helperText={form.error.CampaignCode}
          />
        </Grid>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Campaign Name"
            name="CampaignName"
            onChange={onChange}
            value={form.CampaignName}
            maxlength={500}
            helperText={form.error.CampaignName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          {/* <App.Select
            title="Partner"
            name="Partner"
            onChange={onChange}
            value={form.Partner}
            data={partner}
            helperText={form.error.Partner}
            disabled={!App.service.isNullOrEmpty(ID)}
          /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Website"
            name="Website"
            onChange={onChange}
            value={form.Website}
            helperText={form.error.Website}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Phone No."
            name="PhoneNo"
            onChange={onChange}
            value={form.PhoneNo}
            type="tel"
            maxlength={12}
            helperText={form.error.PhoneNo}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Email"
            name="Email"
            onChange={onChange}
            value={form.Email}
            maxlength={500}
            helperText={form.error.Email}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Input
            title="Description"
            name="Description"
            onChange={onChange}
            value={form.Description}
            maxlength={500}
            helperText={form.error.Description}
            maxRows={3}
            multiline={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <Box display={"flex"}>
            <Box width="calc(100% - 180px)">
              <App.DatePicker
                title="Start Date"
                name="StartDate"
                onChange={onChange}
                value={form.StartDate}
                type="date"
                helperText={form.error.StartDate}
              />
            </Box>
            <Box pl={"10px"} width="180px">
              <App.Time
                placeholder="Start Time"
                name="StartTime"
                onChange={onChange}
                value={form.StartTime}
                type="date"
                disabled={
                  App.service.isNullOrEmpty(form.StartDate) ? true : false
                }
                helperText={form.error.StartTime}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.CheckBox
            title="Never Expire"
            name="NeverExpire"
            onChange={onChange}
            value={form.NeverExpire}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <Box display={"flex"}>
            <Box width="calc(100% - 180px)">
              <App.DatePicker
                title="Expiration Date"
                name="ExpirationDate"
                onChange={onChange}
                value={form.ExpirationDate}
                type="date"
                minDate={form.StartDate}
                helperText={form.error.ExpirationDate}
                disabled={
                  form.NeverExpire || App.service.isNullOrEmpty(form.StartDate)
                }
              />
            </Box>
            <Box pl={"10px"} width="180px">
              <App.Time
                placeholder="Expiration Time"
                name="ExpirationTime"
                onChange={onChange}
                value={form.ExpirationTime}
                type="date"
                helperText={form.error.ExpirationTime}
                disabled={
                  form.NeverExpire ||
                  App.service.isNullOrEmpty(form.ExpirationDate)
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.CheckBox
            title="Private Campaign"
            name="PrivateCampaign"
            onChange={onChange}
            value={form.PrivateCampaign}
            disabled={!App.service.isNullOrEmpty(ID)}
          />
          <Box pl={3.5}>
            <App.Label text="*For Notification " size={12} color={"red"} />
          </Box>
        </Grid>
      </Grid> */}
    </>
  );
};
export default CampaignDetail;
