import service from "undefined-service-web";
// import * as XLSX from "xlsx";
import XLSX from "xlsx-color";
import Label from "./Label";
import MainCard from "./MainCard";
import Input from "./Input";
import Select from "./Select";
import RadioGroup from "./RadioGroup";
import FooterAction from "./FooterAction";
import Enum from "./Enum";
import DatePicker from "./DatePicker";
import Time from "./Time";
import FileUploader from "./FileUploader";
import CheckBox from "./CheckBox";
import ReactEditor from "./Editor";
import ReactWebEditor from "./WebEditor";
import ReactQuill from "./ReactQuill";
import Switch from "./Switch";
import File from "./File";
import color from "./color";
function getExportFileBlob({ columns, data, fileName, SheetName, Header }) {
  const header = Header || Object.keys(data[0]);
  const Columns = columns || Object.keys(data[0]);
  const compatibleData = [];
  data.map((row) => {
    const obj = {};
    Columns.forEach((col, index) => {
      obj[col] = row[col];
    });
    compatibleData.push(obj);
  });

  let wb = XLSX.utils.book_new();

  let ws1 = XLSX.utils.json_to_sheet(compatibleData, { origin: 2 });

  XLSX.utils.sheet_add_aoa(ws1, [["Campaige Name Report"]], { origin: 0 });
  XLSX.utils.sheet_add_aoa(ws1, [[""]], { origin: 1 });
  let objectMaxLength = [];

  data.map((arr) => {
    Object.keys(arr).map((key) => {
      let value = arr[key] === null ? "" : arr[key];

      // if (typeof value === "number") {
      //   return (objectMaxLength[key] = 10);
      // }

      objectMaxLength[key] =
        objectMaxLength[key] >= value.length
          ? objectMaxLength[key]
          : value.length;
    });
  });

  let worksheetCols = Object.keys(objectMaxLength).map((width) => {
    return {
      width: service.isNullOrEmpty(objectMaxLength[width])
        ? 20
        : objectMaxLength[width] + 2 < 10
        ? 20
        : objectMaxLength[width] + 2,
    };
  });

  // worksheet["!cols"] = worksheetCols;
  console.log(worksheetCols);
  ws1["!cols"] = worksheetCols;
  ws1["A1"].s = {
    font: { name: "Courier", sz: 18 },
    // fill: {
    //   fgColor: { rgb: "e5e5e5" },
    // },
  };
  const s = {
    font: { name: "Courier", color: { rgb: "FFFFFF" } },
    fill: {
      fgColor: { rgb: "006838" },
    },
    alignment: {
      horizontal: "center",
    },
    // color: "FFF",
  };
  ws1["A3"].s = s;
  if (ws1["B3"]) ws1["B3"].s = s;
  if (ws1["C3"]) ws1["C3"].s = s;
  if (ws1["D3"]) ws1["D3"].s = s;
  if (ws1["E3"]) ws1["E3"].s = s;
  if (ws1["F3"]) ws1["F3"].s = s;
  if (ws1["G3"]) ws1["G3"].s = s;
  if (ws1["H3"]) ws1["H3"].s = s;
  if (ws1["I3"]) ws1["I3"].s = s;
  if (ws1["J3"]) ws1["J3"].s = s;
  if (ws1["K3"]) ws1["K3"].s = s;
  if (ws1["L3"]) ws1["L3"].s = s;
  // #006838
  console.log(ws1);
  XLSX.utils.book_append_sheet(wb, ws1, SheetName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}
const App = {
  service: service,
  Label: Label,
  MainCard: MainCard,
  Input: Input,
  Select: Select,
  RadioGroup: RadioGroup,
  FooterAction: FooterAction,
  Enum: Enum,
  ValidateFrom: service.ValidateFrom,
  DatePicker: DatePicker,
  FileUploader: FileUploader,
  Time: Time,
  CheckBox: CheckBox,
  ReactEditor: ReactEditor,
  ReactWebEditor: ReactWebEditor,
  ReactQuill: ReactQuill,
  getExportFileBlob: getExportFileBlob,
  Switch: Switch,
  color: color,
  File: File,
};
export default App;
