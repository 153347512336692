import App from "components";
import React from "react";
import moment from "moment";
import Enum from "components/Enum";
const Columns = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <App.Label
            text="Coupon code"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "CouponCode",
      },
      {
        Header: () => (
          <App.Label text="Member" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "FullName",
      },

      {
        Header: () => (
          <App.Label text="Phone no." textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "PhoneNo",
      },
      {
        Header: () => (
          <App.Label text="Birthday" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "DateOfBirth",
        Cell: ({ value, row }) => {
          return !App.service.isNullOrEmpty(row.original.YearOfBirth)
            ? row.original.YearOfBirth
            : App.service.isNullOrEmpty(value)
            ? ""
            : moment(value).format("DD MMM YYYY");
        },
      },
      {
        Header: () => (
          <App.Label text="Age" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Age",
        Cell: ({ value, row }) => {
          return !App.service.isNullOrEmpty(row.original.YearOfBirth)
            ? App.service.CalculateAge(
                new Date(`${row.original.YearOfBirth - 543}-01-01`)
              )
            : App.service.isNullOrEmpty(row.original.DateOfBirth)
            ? ""
            : App.service.CalculateAge(new Date(row.original.DateOfBirth));
        },
      },
      {
        Header: () => (
          <App.Label text="Gender" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Gender",
        Cell: ({ value }) => {
          return App.service.isNullOrEmpty(value)
            ? ""
            : Enum.Gender.find((e) => e.value == value).text;
        },
      },
      {
        Header: () => (
          <App.Label text="Province" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Province",
        Cell: ({ value }) => {
          return App.service.isNullOrEmpty(value)
            ? ""
            : Enum.Province.find((e) => e.id === value).name_th;
        },
      },
      {
        Header: () => (
          <App.Label
            text="เคยซื้อผลิตภัณฑ์"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "UseProducts",
      },
      {
        Header: () => (
          <App.Label
            text="เคยเล่นกิจกรรม"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "UsedBefore",
      },

      {
        Header: () => (
          <App.Label
            text="Coupon Status"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "Status",
      },
      {
        Header: () => (
          <App.Label text="Date" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Date",
        Cell: ({ value, row }) => {
          return App.service.isNullOrEmpty(value)
            ? ""
            : moment(value).format("DD MMM YYYY");
        },
      },
    ],
    []
  );
  return columns;
};

export default Columns;
