import PropTypes from "prop-types";
import React, { forwardRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, CardHeader, Divider, Button } from "@mui/material";
import * as XLSX from "xlsx";
// project import
import Highlighter from "../third-party/Highlighter";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import AddIcon from "@mui/icons-material/Add";
import Label from "../Label";
import { useDispatch } from "react-redux";
import { setLoading, setDialog } from "../../store/reducers/actions";
import App from "components";
// header style
const headerSX = {
  pt: 1,
  pb: 1,
  // pb: 1,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  ({
    border = true,
    boxShadow,
    children,
    content = true,
    contentSX = {},
    darkTitle,
    divider = true,
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    codeHighlight,
    selectedRowIds,
    name,
    Delete,
    Export,
    Impost,
    isImpost,
    IsDelete,
    IsAdd,
    ImpostName,
    toolBar,
    IsSelect,
    permission,
    ...others
  }) => {
    const theme = useTheme();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

    return (
      <Card
        elevation={elevation || 0}
        {...others}
        sx={{
          ...sx,
          border: border ? "1px solid" : "none",
          borderRadius: 2,
          borderColor: "#DDD",
          boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.25)",
          ":hover": {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : "inherit",
          },
          "& pre": {
            m: 0,
            p: "16px !important",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
          height: "600px",
        }}
      >
        {toolBar !== false ? (
          <Box style={{ justifyContent: "space-between", display: "flex" }}>
            {/* card header and action */}
            {!darkTitle && title && (
              <Box p={2}>
                <Label text={title} size={24} color="#000" />
              </Box>
            )}
            {darkTitle && title && (
              <CardHeader
                sx={headerSX}
                title={<Label text={title} size={18} color="#FFF" />}
                action={secondary}
              />
            )}
            <CardHeader
              sx={headerSX}
              title={
                <Box style={{ display: "flex" }}>
                  {IsSelect !== false ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Label
                        text={
                          Object.keys(selectedRowIds).length +
                          " Item(s) selected"
                        }
                        size={18}
                        color="#000"
                      />
                    </Box>
                  ) : null}
                  {IsDelete !== false ? (
                    <Box style={{ padding: 5 }}>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (permission != false) {
                            dispatch(
                              setDialog({
                                open: true,
                                title: "Confirm Delete !",
                                description: "Confirm Delete !",
                                Callback: () => {
                                  setTimeout(() => {
                                    Delete(Object.keys(selectedRowIds));
                                  }, 500);
                                },
                              })
                            );
                          }
                        }}
                        sx={{
                          height: 50,
                          borderRadius: "10px",
                          "&.MuiButton-root:hover": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                          "&.MuiButton-root": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                        }}
                      >
                        <Box style={{ paddingRight: 5, display: "flex" }}>
                          <DeleteIcon style={{ fontSize: 18 }} />
                        </Box>
                        <Label text="Delete" size={18} color="#FFF" />
                      </Button>
                    </Box>
                  ) : null}

                  {isImpost === true ? (
                    <Box style={{ padding: 5 }}>
                      {/* <SheetJSApp Impost={Impost} text={ImpostName} /> */}
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        // type="file"
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          if (permission !== false) {
                            Impost(true);
                          }
                        }}
                        style={{ height: 50, borderRadius: "10px" }}
                        htmlFor="fileimport"
                        sx={{
                          "&.MuiButton-root:hover": {
                            backgroundColor:
                              permission == false ? "#DDD" : "#006838",
                          },
                          "&.MuiButton-root": {
                            backgroundColor:
                              permission == false ? "#DDD" : "#006838",
                          },
                        }}
                      >
                        <Box style={{ paddingRight: 5, display: "flex" }}>
                          <VerticalAlignBottomIcon style={{ fontSize: 18 }} />
                        </Box>

                        <Label
                          text={ImpostName}
                          size={18}
                          color="#FFF"
                          htmlFor="fileimport"
                        />
                      </Button>
                    </Box>
                  ) : null}
                  {isImpost === true ? (
                    <Box style={{ padding: 5 }}>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (permission != false) {
                            window.open(
                              process.env.REACT_APP_KEY_URLAPI +
                                "member/Template/MembersTemplate"
                            );
                          }
                        }}
                        sx={{
                          height: 50,
                          borderRadius: "10px",
                          "&.MuiButton-root:hover": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                          "&.MuiButton-root": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                        }}
                      >
                        <Label text="Members Template" size={18} color="#FFF" />
                      </Button>
                    </Box>
                  ) : null}
                  <Box style={{ padding: 5 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (permission !== false) {
                          Export(Object.keys(selectedRowIds));
                        }
                      }}
                      sx={{
                        height: 50,
                        borderRadius: "10px",
                        "&.MuiButton-root:hover": {
                          backgroundColor:
                            permission === false ? "#DDD" : "#006838",
                        },
                        "&.MuiButton-root": {
                          backgroundColor:
                            permission === false ? "#DDD" : "#006838",
                        },
                      }}
                    >
                      <Box style={{ paddingRight: 5, display: "flex" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C10.5523 0 11 0.447715 11 1V5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H11C10.4696 7 9.96086 6.78929 9.58579 6.41421C9.21071 6.03914 9 5.53043 9 5V1C9 0.447715 9.44772 0 10 0Z" fill="#E5F0EB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H7.5C8.05228 18 8.5 18.4477 8.5 19C8.5 19.5523 8.05228 20 7.5 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7957 0 17V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893L15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V11C16 11.5523 15.5523 12 15 12C14.4477 12 14 11.5523 14 11V6.41421L9.58579 2H3ZM13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071L14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071C12.9024 20.3166 12.9024 19.6834 13.2929 19.2929L14.5858 18H10C9.44771 18 9 17.5523 9 17C9 16.4477 9.44771 16 10 16H14.5858L13.2929 14.7071Z" fill="#E5F0EB"/>
</svg>
                      </Box>
                      <Label text="Export Excel" size={18} color="#FFF" />
                    </Button>
                  </Box>
                  {IsAdd !== false ? (
                    <Box style={{ padding: 5 }}>
                      <Button
                        disableElevation
                        // disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (permission !== false) {
                            navigate("/" + name + "/info");
                          }
                        }}
                        sx={{
                          height: 50,
                          borderRadius: "10px",
                          "&.MuiButton-root:hover": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                          "&.MuiButton-root": {
                            backgroundColor:
                              permission === false ? "#DDD" : "#006838",
                          },
                        }}
                      >
                        <Box style={{ paddingRight: 5, display: "flex" }}>
                          <AddIcon style={{ fontSize: 18 }} />
                        </Box>
                        <Label text="Add" size={18} color="#FFF" />
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              }
              action={secondary}
            />
          </Box>
        ) : null}
        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && (
          <CardContent
            sx={contentSX}
            style={{ padding: "0 0", height: "calc(100% - 100px)" }}
          >
            {children}
          </CardContent>
        )}
        {!content && children}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <Box>
            {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
            <Highlighter codeHighlight={codeHighlight} main>
              {children}
            </Highlighter>
          </Box>
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
};
class SheetJSApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
    };
    this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }
  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });
      this.props.Impost(data.slice(1));
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }
  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <div className="row">
          <div className="col-xs-12">
            <DataInput handleFile={this.handleFile} text={this.props.text} />
          </div>
        </div>
      </DragDropFile>
    );
  }
}
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    // const ref = useRef();
    return (
      <ButtonFile handleChange={this.handleChange} text={this.props.text} />
    );
  }
}

const ButtonFile = ({ handleChange, text }) => {
  const uploadInputRef = useRef(null);
  return (
    <label htmlFor="fileimport">
      <input
        ref={uploadInputRef}
        type="file"
        className="form-control"
        id="fileimport"
        accept={SheetJSFT}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Button
        disableElevation
        fullWidth
        size="large"
        // type="file"
        variant="contained"
        color="primary"
        onClick={(e) => {
          // e.preventDefault();
          //   // document.sele("#file").onClick();
          //  let file = document.querySelector("#fileimport")
          //  console.log(file)
          //  file.click()
          uploadInputRef.current.click();
        }}
        style={{ height: 50, borderRadius: "10px" }}
        htmlFor="fileimport"
      >
        <Box style={{ paddingRight: 5, display: "flex" }}>
          <VerticalAlignBottomIcon style={{ fontSize: 18 }} />
        </Box>

        <Label text={text} size={18} color="#FFF" htmlFor="fileimport" />
      </Button>
    </label>
  );
};

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}
export default MainCard;
