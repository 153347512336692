// material-ui
import { Box, IconButton, Link, useMediaQuery } from "@mui/material";
import { GithubOutlined } from "@ant-design/icons";

// project import
import Search from "./Search";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return <Profile />;
};

export default HeaderContent;
