import { lazy } from "react";
import AuthGuard from "utils/route-guard/AuthGuard";

import MainLayout from "layout/MainLayout";

// import AdminList from "pages/Admin/list";
// import AdminInfo from "pages/Admin/info";

import PrivilegeAndServiceList from "pages/PrivilegeAndService/list";
import PrivilegeInfo from "pages/PrivilegeAndService/privilege";

// import MemberEngagement from "pages/Report/memberengagement";
import MemberEngagement from "pages/Report/memberengagement";
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: "",
    //   children: [
    //     {
    //       path: "default",
    //       element: <AdminList />,
    //     },
    //     {
    //       path: "info/:id?",
    //       element: <AdminInfo />,
    //     },
    //   ],
    // },

    {
      path: "privilegeandservice",
      children: [
        {
          path: "default",
          element: <PrivilegeAndServiceList />,
        },
        {
          path: "privilege/info/:id?",
          element: <PrivilegeInfo />,
        },
      ],
    },

    {
      path: "report",
      children: [
        {
          path: "",
          element: <MemberEngagement />,
        },
      ],
    },
  ],
};

export default MainRoutes;
