import color from "../../components/color";
export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#161E44",
        },
      },
    },
  };
}
