import App from "components";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/reducers/actions";
import { useSnackbar } from "notistack";
import List, { Action } from "components/List";
import React from "react";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
const PrivilegeAndServiceList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [setFooterAction, setTabList] = useOutletContext();
  const [index, setIndex] = React.useState(0);
  const [DataListService, setDataListService] = React.useState([]);
  const [DataService, setDataService] = React.useState([]);
  const [DataListPrivilege, setDataListPrivilege] = React.useState([]);
  const [DataPrivilege, setDataPrivilege] = React.useState([]);
  React.useEffect(() => {
    setFooterAction(null);
    setTabList(null);
    return () => {
      setTabList(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
  const Privilegecolumns = React.useMemo(
    () => [
      {
        Header: () => (
          <App.Label
            text="Campaign Name"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "CampaignName",
      },
      {
        Header: () => (
          <App.Label
            text="Campaign Code"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "CampaignCode",
      },
      {
        Header: () => (
          <App.Label text="Status" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Status",
        Cell: ({ value }) => {
          return (
            <App.Label
              text={
                value === 1 ? "Active" : value === 0 ? "Expired" : "Inactive"
              }
              color={value === 1 ? "green" : value === 0 ? "red" : "orange"}
            />
          );
        },
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },
      {
        Header: () => (
          <App.Label
            text="Start Date"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "StartDate",
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },
      {
        Header: () => (
          <App.Label text="End Date" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "ExpirationDate",
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },

      {
        Header: () => (
          <App.Label text="Amount" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "CouponAamoth",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "80px",
        minWidth: "80px",
        maxWidth: "80px",
      },
      {
        Header: () => (
          <App.Label text="Click" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "Click",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
      },
      {
        Header: () => (
          <Box sx={{ marginTop: "10px" }}>
            <App.Label text="Engage" textAlign="right" size={18} color="#FFF" />
            <Box sx={{ marginTop: "-5px" }}>
              <App.Label
                text={" (Collect / Link / Call)"}
                textAlign="right"
                size={12}
                color="#FFF"
              />
            </Box>
          </Box>
        ),
        accessor: "Engage",
        Cell: ({ value }) => {
          return (
            <Box>
              <App.Label text={value} textAlign="right" />
            </Box>
          );
        },
        width: "80px",
        minWidth: "80px",
        maxWidth: "80px",
      },
      {
        Header: () => (
          <App.Label text="Use" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "Use",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
      },
      {
        Header: "Action",
        accessor: "PrivilegeID",
        Cell: ({ value }) => {
          return (
            <Action
              id={value}
              name="privilegeandservice/privilege"
              Delete={DeletePrivilege}
            />
          );
        },
        width: "70px",
        minWidth: "70px",
        maxWidth: "70px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const Servicecolumns = React.useMemo(
    () => [
      {
        Header: () => (
          <App.Label
            text="Campaign Name"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "CampaignName",
      },
      {
        Header: () => (
          <App.Label
            text="Campaign Code"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "CampaignCode",
      },
      {
        Header: () => (
          <App.Label text="Status" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "Status",
        Cell: ({ value }) => {
          return (
            <App.Label
              text={
                value === 1 ? "Active" : value === 0 ? "Expired" : "Inactive"
              }
              color={value === 1 ? "green" : value === 0 ? "red" : "orange"}
            />
          );
        },
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },
      {
        Header: () => (
          <App.Label
            text="Start Date"
            textAlign="left"
            size={18}
            color="#FFF"
          />
        ),
        accessor: "StartDate",
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },
      {
        Header: () => (
          <App.Label text="End Date" textAlign="left" size={18} color="#FFF" />
        ),
        accessor: "ExpirationDate",
        width: "90px",
        minWidth: "90px",
        maxWidth: "90px",
      },

      {
        Header: () => (
          <App.Label text="Amount" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "CouponAamoth",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "80px",
        minWidth: "80px",
        maxWidth: "80px",
      },
      {
        Header: () => (
          <App.Label text="Click" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "Click",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
      },
      {
        Header: () => (
          <Box sx={{ marginTop: "10px" }}>
            <App.Label text="Engage" textAlign="right" size={18} color="#FFF" />
            <Box sx={{ marginTop: "-5px" }}>
              <App.Label
                text={" (Collect / Link / Call)"}
                textAlign="right"
                size={12}
                color="#FFF"
              />
            </Box>
          </Box>
        ),
        accessor: "Engage",
        Cell: ({ value }) => {
          return (
            <Box>
              <App.Label text={value} textAlign="right" />
            </Box>
          );
        },
        width: "80px",
        minWidth: "80px",
        maxWidth: "80px",
      },
      {
        Header: () => (
          <App.Label text="Use" textAlign="right" size={18} color="#FFF" />
        ),
        accessor: "Use",
        Cell: ({ value }) => {
          return <App.Label text={value} textAlign="right" />;
        },
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px",
      },
      {
        Header: "Action",
        accessor: "PrivilegeID",
        Cell: ({ value }) => {
          return (
            <Action
              id={value}
              name="privilegeandservice/service"
              Delete={DeleteService}
            />
          );
        },
        width: "70px",
        minWidth: "70px",
        maxWidth: "70px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const DeleteService = async (Id, isGetData = true) => {
    dispatch(setLoading(true));
    let _delete = await App.service.postHttp("/service/delete", { id: Id });
    if (_delete.status) {
      if (_delete.data.status) {
        enqueueSnackbar("This is a delete success message!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        // if (isGetData) {
        //   GetDataListService();
        // }
      }
    } else {
      enqueueSnackbar("This is a delete failed message!", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    dispatch(setLoading(false));
  };
  const DeletePrivilege = async (Id, isGetData = true) => {
    dispatch(setLoading(true));
    let _delete = await App.service.postHttp("/privilege/delete", { id: Id });
    if (_delete.status) {
      if (_delete.data.status) {
        enqueueSnackbar("This is a delete success message!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        if (isGetData) {
          GetDataListPrivilege();
        }
      } else {
        enqueueSnackbar(
          "Cannot be deleted " +
            _delete.data.CampaignName +
            " because it has been applied.!",
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          }
        );
      }
    } else {
      enqueueSnackbar("This is a delete failed message!", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    dispatch(setLoading(false));
  };
  // const GetDataListService = async () => {
  //   let _DataList;
  //   try {
  //     _DataList = await App.service.getHttp("/service", dispatch);
  //     if (_DataList.status) {
  //       setDataService(_DataList?.data?.data || []);
  //       setDataListService(_DataList?.data?.data || []);
  //     }
  //   } catch (e) {}
  // };
  const GetDataListPrivilege = async () => {
    let _DataList;
    try {
      _DataList = await App.service.getHttp("/privilege", dispatch);
      if (_DataList.status) {
        setDataPrivilege(_DataList?.data || []);
        setDataListPrivilege(_DataList?.data || []);
        console.log(_DataList?.data);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    // GetDataListService();
    GetDataListPrivilege();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const Export = (selectedRowIds) => {
    const call =
      index === 0
        ? [
            "CampaignName",
            "CampaignCode",
            "Status",
            "StartDate",
            "ExpirationDate",
            "CouponAamoth",
            "Click",
            "Engage",
            "Use",
          ]
        : [
            "ServiceTitle",
            "CategoryName",
            "Website",
            "PhoneNo",
            "Email",
            "Click",
            "Engage",
            "Use",
          ];
    if (selectedRowIds.length > 0) {
      // eslint-disable-next-line array-callback-return
      const data = index === 0 ? DataPrivilege : DataService;
      const items = data.filter(
        // eslint-disable-next-line array-callback-return
        (item, i) => {
          if (
            !App.service.isNullOrEmpty(
              selectedRowIds.find((e) => parseInt(e) === i)
            )
          ) {
            return item;
          }
        }
      );
      console.log(items);
      App.getExportFileBlob({
        columns: call,
        Header: call,
        data: items,
        fileName: index === 0 ? "Privilege" : "Service",
        SheetName: index === 0 ? "Privilege" : "Service",
      });
    } else {
      App.getExportFileBlob({
        columns: call,
        Header: call,
        data: index === 0 ? DataPrivilege : DataService,
        fileName: index === 0 ? "Privilege" : "Service",
        SheetName: index === 0 ? "Privilege" : "Service",
      });
    }
  };
  const [Searchvalue, setSearchvalue] = React.useState("");
  const onSearch = ({ value }) => {
    setSearchvalue(value);
  };
  React.useEffect(() => {
    if (!App.service.isNullOrEmpty(Searchvalue)) {
      let _Searchvalue = Searchvalue.toLowerCase().trim();
      index === 0
        ? setDataListPrivilege(
            // eslint-disable-next-line array-callback-return
            DataPrivilege.filter((e) => {
              if (
                (e.CampaignName || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e.CampaignCode || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e.CouponAamoth || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e.Status === 1
                  ? "Active"
                  : e.Status === 0
                  ? "Expired"
                  : "Inactive"
                )
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.StartDate || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.ExpirationDate || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Click || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Engage || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Use || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue)
              ) {
                return e;
              }
            })
          )
        : setDataListService(
            // eslint-disable-next-line array-callback-return
            DataService.filter((e) => {
              if (
                e.ServiceTitle.toLowerCase().trim().includes(_Searchvalue) ||
                e.CategoryName.toLowerCase().trim().includes(_Searchvalue) ||
                (e?.Website || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.PhoneNo || "")
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Email || "").toLowerCase().trim().includes(_Searchvalue) ||
                (e?.Click || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Engage || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue) ||
                (e?.Use || "")
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(_Searchvalue)
              ) {
                return e;
              }
            })
          );
    } else {
      index === 0
        ? setDataListPrivilege(DataPrivilege)
        : setDataListService(DataService);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Searchvalue]);
  return (
    <List
      title={index === 0 ? "Privilege" : "Service"}
      name={
        index === 0
          ? "privilegeandservice/privilege"
          : "privilegeandservice/service"
      }
      columns={index === 0 ? Privilegecolumns : Servicecolumns}
      data={index === 0 ? DataListPrivilege : DataListService}
      onSearch={onSearch}
      Searchvalue={Searchvalue}
      Export={Export}
      Delete={async (e) => {
        let key = [];
        // eslint-disable-next-line array-callback-return
        e.map((e, i) => {
          key.push(DataListPrivilege[e].PrivilegeID);
        });
        for (let i = 0; i < key.length; i++) {
          await DeletePrivilege(key[i]);
        }
        GetDataListPrivilege();
      }}
    />
  );
};
export default PrivilegeAndServiceList;
