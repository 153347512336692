import * as React from "react";
// import dayjs from "dayjs";
// import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InputLabel from "@mui/material/InputLabel";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Label from "../Label";
import color from "../color";
import FormHelperText from "@mui/material/FormHelperText";
import service from "undefined-service-web";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: "20px",
    padding: "5px 5px",
    boxSizing: "content-box",

    borderRadius: 10,
    position: "relative",
    color: color.main,
    border: "1px solid #B8B9BA",
    backgroundColor: "#FFF",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      borderColor: "#161e44",
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    backgroundColor: "#ededee !important",
    border: "1px solid #ededee",
  },
  "&.Mui-error": {
    "& input": {
      border: "1px solid red",
      "&::placeholder": {
        color: "red",
      },
    },
    "& button ": {
      color: "red",
    },
  },
}));
export default function MaterialUIPickers(props) {
  const {
    title,
    name,
    maxDate,
    minDate,
    onChange,
    value,
    disabled,
    helperText,
    isLabel,
    IsCancel,
  } = props;
  const handleChange = (newValue) => {
    console.log(newValue);
    onChange({ name: name, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl
        variant="standard"
        fullWidth
        sx={{
          height: 40,
          "& .MuiInputBase-input": {
            height: "30px !important",
            border: "1px solid #D6D6D8 !important",
            borderRadius: "4px !important",
          },
        }}
      >
        {isLabel !== false ? (
          <InputLabel shrink htmlFor={name} style={{ transform: "none" }}>
            <Label
              text={title}
              size={18}
              fontWeight="bold"
              color={
                helperText != null && helperText !== undefined
                  ? "#ED4040"
                  : "#161E44"
              }
            />
          </InputLabel>
        ) : null}
        <MobileDatePicker
          label={title}
          inputFormat="DD/MM/YYYY"
          value={value || null}
          onChange={handleChange}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params) => (
            <BootstrapInput
              {...params}
              placeholder={title}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    position: "absolute",
                    right: "10px",
                  }}
                >
                  {IsCancel === true && !service.isNullOrEmpty(value) ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange({ name: name, value: null });
                      }}
                      edge="end"
                      disabled={disabled || false}
                    >
                      <CloseIcon sx={{ color: color.main }} />
                    </IconButton>
                  ) : null}
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    disabled={disabled || false}
                  >
                    <CalendarMonthIcon sx={{ color: color.main }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          className={
            helperText != null && helperText != undefined ? "Mui-error" : ""
          }
          disabled={disabled || false}
        />
        <FormHelperText id="component-error-text" style={{ color: "red" }}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </LocalizationProvider>
  );
}
