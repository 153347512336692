import { lazy } from "react";
import GuestGuard from "utils/route-guard/GuestGuard";
// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthForgot = Loadable(lazy(() => import("pages/authentication/Forgot")));
const AuthConfirmPassword = Loadable(
  lazy(() => import("pages/authentication/ConfirmPassword"))
);
const LoginRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "forgot",
      element: <AuthForgot />,
    },
    {
      path: "confirmpassword/:id",
      element: <AuthConfirmPassword />,
    },
  ],
};

export default LoginRoutes;
