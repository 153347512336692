import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import App from "components";
// project import
import { activeItem } from "store/reducers/menu";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level, isShow }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toLowerCase()
      .toString()
      .split("/")
      .findIndex((id) => id.toLowerCase() === item.id.toLowerCase());

    if (currentIndex > -1) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [document.location.pathname]);

  const textColor = "text.primary";
  const iconSelectedColor = "primary.main";
  console.log(item.user != "superadmin");
  console.log(localStorage.getItem("Username"));
  const user = localStorage.getItem("Username") || "";
  if (
    user.toLowerCase() == (item.user || "").toLowerCase() ||
    user.toLowerCase() == "superadmin"
  ) {
    return (
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={() => itemHandler(item.id)}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          display: isShow == false ? "none" : "",
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          pt: 0,
          pb: 0,
          mt: 1,
          borderRadius: 2,
          ...(drawerOpen && {
            "&:hover": {
              bgcolor: "#338660",
            },
            "&.Mui-selected": {
              bgcolor: "#338660",
              // borderRight: `2px solid ${theme.palette.primary.main}`,
              color: iconSelectedColor,
              "&:hover": {
                color: iconSelectedColor,
                bgcolor: "#338660",
              },
            },
          }),
          ...(!drawerOpen && {
            "&:hover": {
              bgcolor: "transparent",
            },
            "&.Mui-selected": {
              "&:hover": {
                bgcolor: "transparent",
              },
              bgcolor: "transparent",
            },
          }),
          height: 52,
        }}
      >
        {/* {item.id } */}
        {itemIcon &&
          (item.id === "Report" ? (
            <ListItemIcon
              sx={{
                // minWidth: 28,
                color: isSelected ? iconSelectedColor : textColor,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,

                  "&:hover": {
                    bgcolor: "secondary.lighter",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  }),
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M4 2.97574H20C20.55 2.97574 21.021 3.17174 21.413 3.56374C21.805 3.95574 22.0007 4.42641 22 4.97574V15.9757C22 16.5257 21.804 16.9967 21.412 17.3887C21.02 17.7807 20.5493 17.9764 20 17.9757H16V21.3507C16 21.7341 15.8417 22.0217 15.525 22.2137C15.2083 22.4057 14.8833 22.4181 14.55 22.2507L12.45 21.2007C12.3167 21.1174 12.1667 21.0757 12 21.0757C11.8333 21.0757 11.6833 21.1174 11.55 21.2007L9.45 22.2507C9.11667 22.4174 8.79167 22.4051 8.475 22.2137C8.15834 22.0224 8 21.7347 8 21.3507V17.9757H4C3.45 17.9757 2.979 17.7797 2.587 17.3877C2.195 16.9957 1.99934 16.5251 2 15.9757V4.97574C2 4.42574 2.196 3.95474 2.588 3.56274C2.98 3.17074 3.45067 2.97507 4 2.97574ZM4 13.9757H20V10.9757H4V13.9757Z"
                  fill="white"
                />
              </svg>
            </ListItemIcon>
          ) : (
            <ListItemIcon
              sx={{
                // minWidth: 28,
                color: isSelected ? iconSelectedColor : textColor,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,

                  "&:hover": {
                    bgcolor: "secondary.lighter",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  }),
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              {/* {itemIcon} */}
              <App.Label
                style={{ display: "flex", alignItems: "center" }}
                text={itemIcon}
                size={14}
                color={"#FFF"}
              />
            </ListItemIcon>
          ))}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            sx={{ pl: 1 }}
            primary={
              <App.Label pl={2} text={item.title} size={14} color={"#FFF"} />
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    );
  }
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
