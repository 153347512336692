import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Label from "../Label";
import color from "../color";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import service from "undefined-service-web";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: "20px",
    padding: "5px 5px",
    boxSizing: "content-box",

    borderRadius: 10,
    position: "relative",
    color: color.main,
    border: "1px solid #B8B9BA",
    backgroundColor: "#FFF",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      borderColor: "#161e44",
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    backgroundColor: "#ededee !important",
    border: "1px solid #ededee",
  },
  "&.Mui-error": {
    "& input": {
      border: "1px solid red",
      "&::placeholder": {
        color: "red",
      },
    },
  },
  "& textarea": {
    height: "60px !important",
  },
}));
const inputUC = (props) => {
  const {
    title,
    name,
    isTitle,
    onChange,
    value,
    helperText,
    type,
    maxlength = 4000,
    multiline,
    maxRows,
    disabled,
    isLabel,
    IsCancel,
  } = props;
  const Change = (e) => {
    onChange({
      name: name,
      value: e.target.value,
    });
  };
  // console.log(value)
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        height: 40,
        "& .MuiInputBase-input": {
          height: "30px !important",
          border: "1px solid #D6D6D8 !important",
          borderRadius: "4px !important",
        },
      }}
    >
      {isLabel !== false ? (
        <InputLabel shrink htmlFor={name} style={{ transform: "none" }}>
          <Label
            text={isTitle !== false ? title : ""}
            size={18}
            fontWeight="bold"
            color={
              helperText != null && helperText !== undefined
                ? "#ED4040"
                : "#161E44"
            }
          />
        </InputLabel>
      ) : null}
      <BootstrapInput
        placeholder={title}
        id={name}
        onChange={Change}
        value={value}
        type={type || "text"}
        error={helperText != null && helperText != undefined}
        maxlength={maxlength || 4000}
        multiline={multiline || false}
        maxRows={maxRows || 1}
        disabled={disabled || false}
        onKeyDown={(e) => {
          if (
            (value || "").length > maxlength &&
            "Delete" != e.key &&
            "Backspace" != e.key
          ) {
            e.preventDefault();
          } else {
            if (type == "tel" || type == "Identity") {
              if (
                !"+0123456789".includes(e.key) &&
                "Delete" != e.key &&
                "Backspace" != e.key
              ) {
                e.preventDefault();
              }
              console.log(e.key);
            }
          }
        }}
        endAdornment={
          <InputAdornment
            position="end"
            style={{
              position: "absolute",
              right: "10px",
            }}
          >
            {IsCancel === true && !service.isNullOrEmpty(value) ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange({ name: name, value: "" });
                }}
                edge="end"
                disabled={disabled || false}
              >
                <CloseIcon sx={{ color: color.main }} />
              </IconButton>
            ) : null}
          </InputAdornment>
        }
      />
      <FormHelperText id="component-error-text" style={{ color: "#ED4040" }}>
        <Label text={helperText} size={15} color="#ED4040" />
      </FormHelperText>
    </FormControl>
  );
};

inputUC.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  isTitle: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  maxRows: PropTypes.number,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  maxlength: PropTypes.number,
  type: PropTypes.string,
};
export default inputUC;
