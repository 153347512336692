import PropTypes from "prop-types";
import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import ProfileTab from "./ProfileTab";
import SettingTab from "./SettingTab";

// assets
import avatar1 from "assets/images/users/avatar-1.png";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";
import { setAuthUser } from "../../../../../store/reducers/actions.js";
// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleLogout = async () => {
    // logout
    localStorage.removeItem("token");
    localStorage.removeItem("Username");
    dispatch(setAuthUser(null));
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? "transparent" : "transparent",
          borderRadius: 1,
          // "&:hover": { bgcolor: "secondary.lighter" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
<circle cx="25.5977" cy="25.5" r="25" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.8702 20.0454C32.8702 21.9743 32.1039 23.8241 30.74 25.188C29.3761 26.5519 27.5263 27.3182 25.5974 27.3182C23.6686 27.3182 21.8187 26.5519 20.4548 25.188C19.0909 23.8241 18.3247 21.9743 18.3247 20.0454C18.3247 18.1166 19.0909 16.2667 20.4548 14.9028C21.8187 13.5389 23.6686 12.7727 25.5974 12.7727C27.5263 12.7727 29.3761 13.5389 30.74 14.9028C32.1039 16.2667 32.8702 18.1166 32.8702 20.0454ZM29.2338 20.0454C29.2338 21.0099 28.8507 21.9348 28.1687 22.6167C27.4868 23.2987 26.5619 23.6818 25.5974 23.6818C24.633 23.6818 23.7081 23.2987 23.0261 22.6167C22.3442 21.9348 21.9611 21.0099 21.9611 20.0454C21.9611 19.081 22.3442 18.1561 23.0261 17.4741C23.7081 16.7922 24.633 16.4091 25.5974 16.4091C26.5619 16.4091 27.4868 16.7922 28.1687 17.4741C28.8507 18.1561 29.2338 19.081 29.2338 20.0454Z" fill="#1E1E1E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.5977 5.5C14.5522 5.5 5.59766 14.4545 5.59766 25.5C5.59766 36.5455 14.5522 45.5 25.5977 45.5C36.6431 45.5 45.5977 36.5455 45.5977 25.5C45.5977 14.4545 36.6431 5.5 25.5977 5.5ZM9.23402 25.5C9.23402 29.3 10.5304 32.7982 12.7031 35.5764C14.229 33.5725 16.1975 31.9486 18.4548 30.8315C20.7121 29.7143 23.1972 29.1342 25.7158 29.1364C28.2019 29.134 30.6557 29.6991 32.8903 30.7887C35.1248 31.8783 37.0812 33.4635 38.6104 35.4236C40.1857 33.3575 41.2465 30.9458 41.7047 28.3883C42.163 25.8308 42.0057 23.201 41.2458 20.7163C40.4859 18.2317 39.1453 15.9637 37.3349 14.1001C35.5245 12.2364 33.2963 10.8307 30.8347 9.99911C28.3731 9.16756 25.7489 8.93413 23.1792 9.31813C20.6095 9.70214 18.1682 10.6925 16.0572 12.2074C13.9463 13.7222 12.2264 15.7179 11.0399 18.0294C9.85338 20.3409 9.23435 22.9018 9.23402 25.5ZM25.5977 41.8636C21.8412 41.8693 18.1981 40.577 15.2849 38.2055C16.4575 36.5268 18.0183 35.1562 19.8344 34.2103C21.6505 33.2645 23.6682 32.7713 25.7158 32.7727C27.7379 32.7711 29.7313 33.252 31.5303 34.1753C33.3292 35.0987 34.882 36.4379 36.0595 38.0818C33.1237 40.5304 29.4205 41.869 25.5977 41.8636Z" fill="#1E1E1E"/>
</svg>
          {/* <Typography variant="subtitle1">John Doe</Typography> */}
          <Label
            text={localStorage.getItem("Username")}
            size={28}
            color="#FFF"
          />
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 0 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <AccountCircleIcon
                              style={{ fontSize: 50, color: "#161E44" }}
                            />
                            {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                            <Stack>
                              {/* <Typography variant="h6">John Doe</Typography> */}
                              {/* <Typography variant="body2" color="textSecondary">
                                                                UI/UX Designer
                                                            </Typography> */}
                              <Label
                                text={localStorage.getItem("Username")}
                                size={18}
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="large"
                            color="secondary"
                            onClick={handleLogout}
                          >
                            <LogoutOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {/* {open && (
                                            <>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs
                                                        variant="fullWidth"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="profile tabs"
                                                    >
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Profile"
                                                            {...a11yProps(0)}
                                                        />
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Setting"
                                                            {...a11yProps(1)}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel value={value} index={0} dir={theme.direction}>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                </TabPanel>
                                                <TabPanel value={value} index={1} dir={theme.direction}>
                                                    <SettingTab />
                                                </TabPanel>
                                            </>
                                        )} */}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
