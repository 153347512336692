import React from "react";
import { styled } from "@mui/system";
import { useTable, usePagination, useRowSelect } from "react-table";
import Styled from "styled-components";
import Card from "./Card";
import ActionUC from "./Action";
import Label from "../Label";
import color from "../color";
import InputUC from "../Input";
import DatePicker from "../DatePicker";
import Select from "../Select";
import Box from "@mui/material/Box";
import { Scrollbar } from "react-scrollbars-custom";
import Pagination from "@mui/material/Pagination";
import { Grid, Button } from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

// ==============================|| SAMPLE PAGE ||============================== //
const ScrollbarTable = Styled(Scrollbar)(() => ({
  "& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX": {
    backgroundColor: "rgb(240 240 242) !important",
    height: "7px !important",
    width: "100% !important",
    left: "0 !important",
  },
  "& .ScrollbarsCustom-Thumb.ScrollbarsCustom-TrackX": {
    backgroundColor: color.main + " !important",
  },
}));
function Table({
  columns,
  data,
  name,
  title,
  Delete,
  Export,
  isImpost,
  Impost,
  ImpostName,
  toolBar,
  onSearch,
  Searchvalue,
  IsSearch,
  IsSelect,
  IsFooter,
  isHeight,
  IsDelete,
  IsAdd,
  IsSearchDate,
  StartDate,
  EndDate,
  permission,
  DisplayReport,
  IsFilter,
  Filter,
  FilterOption,
  onFilter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    // selectedFlatRows,
    state: {
      // pageIndex,
      pageSize,
      selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      if (IsSelect !== false) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  return (
    <Box height={"auto"}>
      <Card
        title={title}
        selectedRowIds={selectedRowIds}
        name={name}
        Delete={Delete}
        Export={Export}
        isImpost={isImpost}
        Impost={Impost}
        ImpostName={ImpostName}
        toolBar={toolBar}
        IsDelete={IsDelete}
        IsAdd={IsAdd}
        IsSelect={IsSelect}
        permission={permission}>
        {IsSearch !== false ? (
          <Grid container spacing={1} mt={1}>
            <Grid
              item
              {...{ xs: 12 }}
              style={{ display: "flex" }}>
              <Box mb={2} width={300} ml={2}>
                <InputUC
                  isLabel={false}
                  title="Search"
                  name="Search"
                  onChange={onSearch}
                  value={Searchvalue}
                  maxlength={500}
                  IsCancel={true}
                />
              </Box>
              {IsFilter === true ? (
                <Box mb={2} width={250} ml={2} height={40}>
                  <Select
                    title=""
                    name="Filter"
                    value={Filter}
                    onChange={onFilter}
                    data={FilterOption}
                    isTitle={false}
                  />
                </Box>
              ) : null}
              {IsSearchDate === true ? (
                <Box mb={2} width={150} ml={2} height={40}>
                  <DatePicker
                    isLabel={false}
                    title="Start Date"
                    name="StartDate"
                    onChange={onSearch}
                    value={StartDate}
                    type="date"
                    IsCancel={true}
                  />
                </Box>
              ) : null}
              {IsSearchDate === true ? (
                <Box mb={2} width={150} ml={2}>
                  <DatePicker
                    isLabel={false}
                    title="End Date"
                    name="EndDate"
                    onChange={onSearch}
                    value={EndDate}
                    type="date"
                    IsCancel={true}
                  />
                </Box>
              ) : null}
              {IsSearchDate === true ? (
                <Box mb={2} width={190} ml={2}>
                  <Button
                    disableElevation
                    // fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => DisplayReport()}
                    sx={{
                      height: 40,
                      borderRadius: "10px",
                      width: "180px",
                      "&.MuiButton-root:hover": {
                        backgroundColor:
                          permission == false ? "#DDD" : "#006838",
                      },
                      "&.MuiButton-root": {
                        backgroundColor:
                          permission == false ? "#DDD" : "#006838",
                      },
                    }}>
                    <Label text="Display Report" size={16} color="#FFF" />
                  </Button>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        <Box sx={{ height: isHeight === false ? "10px" : "" }}></Box>
        <ScrollbarTable
          style={{
            width: "100%",
            height: isHeight === false ? "100%" : "calc(100% - 120px)",
            // maginTop: "10px",
          }}>
          <Box
            ml={2}
            mr={2}
            style={{
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}>
            <table
              {...getTableProps()}
              style={{
                borderSpacing: "0",
                width: "100%",
                whiteSpace: "nowrap",
              }}>
              <thead style={{}}>
                {headerGroups.map((headerGroup, Groupindex) => (
                  <tr
                    key={Groupindex}
                    {...headerGroup.getHeaderGroupProps()}
                    style={{
                      background:
                        "linear-gradient(233.3deg, rgb(0 104 56) -28.7%, rgb(30 30 30) 117.45%)",
                      height: 65,
                    }}>
                    {headerGroup.headers.map((column, columnindex) => {
                      // console.log("column", column.maxWidth);
                      let maxWidth = column.maxWidth.toString().includes("px")
                        ? column.maxWidth
                        : null;
                      const TH = styled("th")({
                        paddingLeft: 10,
                        paddingRight: 10,
                        maxWidth: column.id === "selection" ? 50 : "",
                        width: column.id === "selection" ? 50 : "",
                        "& div":
                          column.id === "selection"
                            ? {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "& input[type='checkbox']:checked": {
                                  accentColor: "#1E1E1E",
                                },
                                "& input ": {
                                  width: 18,
                                  height: 18,
                                },
                                maxWidth: 50,
                              }
                            : { maxWidth: maxWidth || 250 },
                      });
                      return (
                        <TH key={columnindex} {...column.getHeaderProps()}>
                          <Label
                            text={column.render("Header")}
                            size={18}
                            color="#FFF"
                          />
                        </TH>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()} style={{ height: 30 }}>
                      {row.cells.map((cell, cellindex) => {
                        let maxwidth = cell.column.maxWidth
                          .toString()
                          .includes("px")
                          ? cell.column.maxWidth
                          : null;
                        // console.log("maxWidth", cell.column);
                        const TD = styled("td")({
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderBottom: "1px solid #ddd",
                          maxWidth:
                            cell.column.id === "selection"
                              ? 50
                              : maxwidth || "",
                          width:
                            cell.column.id === "selection"
                              ? 50
                              : maxwidth || "",
                          "& div":
                            cell.column.id === "selection"
                              ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  "& input[type='checkbox']:checked": {
                                    accentColor: "#1E1E1E",
                                  },
                                  "& input ": {
                                    width: 18,
                                    height: 18,
                                  },
                                  maxWidth: 50,
                                }
                              : {
                                  maxWidth: maxwidth || 250,
                                  overflowWrap: "break-word",
                                  WebkitLineClamp: 1,
                                  // width: "100%",
                                  display: "flow-root ",
                                },
                        });

                        return (
                          <TD key={cellindex} {...cell.getCellProps()}>
                            <Label
                              text={cell.render("Cell")}
                              size={14}
                              color="#333436"
                            />
                          </TD>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </ScrollbarTable>
        {IsFooter !== false ? (
          <Box
            className="pagination"
            display={"flex"}
            justifyContent="space-between"
            pl={2}
            pr={2}
            pt={1}>
            <Box display={"flex"}>
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                pr={1}>
                <Label text={"Row per page"} size={14} color="#252525" />
              </Box>

              <Select
                title=""
                name="CategoryID"
                onChange={(e) => {
                  setPageSize(Number(e.value));
                }}
                value={pageSize}
                data={[
                  { value: 10, text: "10" },
                  { value: 20, text: "20" },
                  { value: 30, text: "30" },
                  { value: 40, text: "40" },
                  { value: 50, text: "50" },
                ]}
                isTitle={false}
              />
            </Box>
            <Pagination
              count={pageCount}
              showFirstButton
              showLastButton
              onChange={(e, v) => {
                gotoPage(v - 1);
              }}
              variant="outlined"
              shape="circular"
              sx={{
                "& .MuiButtonBase-root.Mui-selected": {
                  backgroundColor: "#1E1E1E",
                  color: "#FFF",
                  border: "1px solid #1E1E1E",
                },
                "& .MuiButtonBase-root": {
                  color: "#006838",
                  fontSize: 14,
                  border: "1px solid #006838",
                },
                "& .Mui-disabled": {
                  backgroundColor: "#D9D9D9",
                  border: "1px solid #D9D9D9",
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowLeftIcon,
                    next: ArrowRightIcon,
                    first: SkipPreviousIcon,
                    last: SkipNextIcon,
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        ) : null}
      </Card>
    </Box>
  );
}
const ListUC = (props) => {
  // const { columns, data, title, name, Delete, Export } = props;
  return <Table {...props} />;
};

export const Action = ActionUC;
export default ListUC;
