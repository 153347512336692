import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Label = (props) => {
  const {
    text,
    id,
    name,
    color,
    size,
    fontWeight,
    style,
    mt,
    mb,
    px,
    py,
    numberOfLine,
    onClick,
    textAlign
  } = props;
  const getToggleprops = () => {
    return {
      WebkitWserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      fontSize: size || 16,
      color: color || "#161E44",
      fontWeight: fontWeight || "",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: numberOfLine,
    };
  };
  return (
    <Box
      mt={mt}
      mb={mb}
      id={id}
      name={name}
      px={px}
      py={py}
      style={{
        ...getToggleprops(),
        ...style,
      }}
      textAlign={textAlign}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};
Label.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  fontWeight: PropTypes.string,
  style: PropTypes.object,
  mt: PropTypes.number,
  mb: PropTypes.number,
  numberOfLine: PropTypes.number,
};
export default Label;
