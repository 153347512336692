import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import service from "undefined-service-web";
// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  // const { isLoggedIn } = useAuth();
  const { authUser } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!service.isNullOrEmpty(authUser)) {
      navigate("/", { replace: true });
    }
  }, [authUser, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
