import * as React from "react";
import { Box, Switch } from "@mui/material";
import Label from "../Label";
export default function SwitchUC(props) {
  const { title, name, onChange, value, disabled } = props;
  return (
    <Box
      display={"flex"}
      // justifyContent="space-between"
      alignItems={"center"}
    >
      <Switch
        // {...{ inputProps: { "aria-label": "Switch demo" } }}
        checked={value}
        onChange={(e, checked) => {
          console.log(checked);
          onChange({
            name: name,
            value: checked,
          });
        }}
        disabled={disabled}
      />
      <Label size={16} text={title} fontWeight="bold" />
    </Box>
  );
}
