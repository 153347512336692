import App from "components";
import { Grid } from "@mui/material";
const CampaignDetail = (props) => {
  const { form, onChange, ID, unit } = props;
  const groupBy = (arr, key) => {
    const initialValue = {};
    return arr.reduce((acc, cval) => {
      const myAttribute = cval[key];
      acc[myAttribute] = [...(acc[myAttribute] || []), cval];
      return acc;
    }, initialValue);
  };
  let Project = [];
  Object.keys(groupBy(unit, "Unit")).filter((e) => {
    Project.push({
      value: e,
      text: e,
    });
  });
  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.RadioGroup
            title=""
            name="Type"
            data={[...App.Enum.DistributionType]}
            onChange={onChange}
            value={form.Type}
            helperText={form.error.Type}
            row={false}
            disabled={!App.service.isNullOrEmpty(ID)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          {/* <App.Input
            title="Unit"
            name="Unit"
            onChange={onChange}
            value={form.Unit}
            maxlength={500}
            helperText={form.error.Unit}
            disabled={form.Type === 0 || !App.service.isNullOrEmpty(ID)}
          /> */}
          <App.Select
            title="Project"
            name="Project"
            value={form.Project}
            data={Project}
            multiple={true}
            onChange={onChange}
            disabled={form.Type === 0 || !App.service.isNullOrEmpty(ID)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.Select
            title="Home Address"
            name="UnitNo"
            // isTitle={false}
            value={form.UnitNo}
            data={(() => {
              let item = [];
              unit.filter((e) => {
                if (form.Project.includes(e.Unit)) {
                  item.push({ value: e.value, text: e.UnitNo });
                }
              });
              return item;
            })()}
            multiple={true}
            onChange={onChange}
            disabled={form.Type === 0 || !App.service.isNullOrEmpty(ID)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item {...{ xs: 12, sm: 6, md: 6, lg: 6 }}>
          <App.RadioGroup
            title="Gender"
            name="Gender"
            data={[...[{ value: 2, text: "All" }], ...App.Enum.Gender]}
            onChange={onChange}
            value={form.Gender}
            helperText={form.error.Gender}
            disabled={!App.service.isNullOrEmpty(ID)}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CampaignDetail;
