// material-ui
import App from "components";
import React from "react";
import { useDispatch } from "react-redux";
import { setLoading, setDialog } from "../../../store/reducers/actions";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import CampaignDetail from "./CampaignDetail";
import CouponDetail from "./CouponDetail";
import Distribution from "./Distribution";
// import Condition from "./Condition";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
const PrivilegeInfo = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [setFooterAction] = useOutletContext();
  const [index, setIndex] = React.useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [ID, setID] = React.useState(id);
  const [unit, setunit] = React.useState([]);
  const [isloading, setisloading] = React.useState(true);
  const [partner, setpartner] = React.useState([]);
  const [category, setcategory] = React.useState([]);
  const [isPhoto0, setIsPhoto0] = React.useState(false);
  const [isPhoto1, setIsPhoto1] = React.useState(false);
  const [isPhoto2, setIsPhoto2] = React.useState(false);
  const [isPhoto3, setIsPhoto3] = React.useState(false);
  const [isPhoto4, setIsPhoto4] = React.useState(false);
  const [CouponList, setCouponList] = React.useState([]);
  const [form, setForm] = React.useState({
    NeverExpire: true,
    PrivateCampaign: false,
    IsSpecialMessage: false,
    DateCondition: 2,
    LimitCondition: 0,
    Gender: 2,
    ButtonType: 0,
    Type: 0,
    UnitNo: [],
    Project: [],
    error: {},
    Partner: null,
    Category: null,
    CouponAamoth: 0,
  });
  const [isModify, setIsModify] = React.useState(false);
  // const [isUpdatePhoto, setIsUpdatePhoto] = React.useState(false);
  const onChange = (e) => {
    setIsModify(true);
    let _form = form;
    let error = form.error;
    error[e.name] = null;
    let value = e.value;
    let partnerdefault = {};
    if (e.name === "Partner") {
      let _partner = partner.find((p) => p.value === e.value);
      partnerdefault = {
        Website: _partner?.Website || "",
        PhoneNo: _partner?.PhoneNo || "",
        Email: _partner?.Email || "",
      };
    }
    if (e.name === "StartDate") {
      error["StartTime"] = null;
    }
    if (e.name === "ExpirationDate") {
      error["ExpirationTime"] = null;
    }
    if (e.name === "NeverExpire") {
      error["ExpirationDate"] = null;
      error["ExpirationTime"] = null;
    }

    setForm({
      ..._form,
      ...(e.name === "IsSpecialMessage"
        ? {
            SpecialMessage: "",
          }
        : {}),
      ...(e.name === "ButtonType"
        ? {
            ButtonInput: "",
          }
        : {}),
      ...(e.name === "Type"
        ? {
            UnitNo: [],
            Project: [],
          }
        : {}),
      ...(e.name === "NeverExpire"
        ? {
            ExpirationDate: "",
            ExpirationTime: "",
            // StartDate: "",
            // StartTime: "",
          }
        : {}),
      ...(e.name === "StartDate"
        ? {
            StartTime: new Date(
              moment(e.value).format("yyy-MM-DD") + " 00:00:00"
            ),
          }
        : {}),

      ...(e.name === "ExpirationDate"
        ? {
            ExpirationTime: new Date(
              moment(e.value).format("yyy-MM-DD") + " 00:00:00"
            ),
          }
        : {}),

      ...partnerdefault,

      [e.name]: value,
      error: error,
    });
    switch (e.name) {
      case "image0":
        setIsPhoto0(true);
        break;
      case "image1":
        setIsPhoto1(true);
        break;
      case "image2":
        setIsPhoto2(true);
        break;
      case "image3":
        setIsPhoto3(true);
        break;
      case "image4":
        setIsPhoto4(true);
        break;
      default:
        break;
    }
  };
  const validate = () => {
    let isValidate = true;
    let schema = {
      CampaignCode: {
        isNull: "Please Enter Campaign Code.",
      },
      CampaignName: {
        isNull: "Please Enter Campaign Name.",
      },

      // Partner: {
      //   isNull: "Please Enter Partner.",
      // },
      // Category: {
      //   isNull: "Please Enter Category.",
      // },
      CouponTitle: {
        isNull: "Please Enter Coupon Title.",
      },
      // CouponAamoth: {
      //   isNull: "Please Enter Coupon Aamoth.",
      // },
      StartDate: {
        isNull: "Please Enter Start Date.",
      },
      StartTime: {
        isNull: "Please Enter Start Time.",
      },
    };
    if (!form.NeverExpire) {
      schema = {
        ...schema,
        ...{
          ExpirationDate: {
            isNull: "Please Enter Expiration Date.",
          },
          ExpirationTime: {
            isNull: "Please Enter Expiration Time.",
          },
        },
      };
    }
    if (form.ButtonType === 0) {
      // schema = {
      //   ...schema,
      //   ...{
      //     CouponAamoth: {
      //       isNull: "Please Enter Coupon Aamoth.",
      //     },
      //   },
      // };
    } else {
      schema = {
        ...schema,
        ...{
          ButtonInput: {
            isNull:
              "Please Enter " +
              (form.ButtonType === 1
                ? "Call"
                : form.ButtonType === 2
                ? "Email"
                : form.ButtonType === 3
                ? "Show Information"
                : "Link to Partner"),
          },
        },
      };
    }
    let ValidateFrom = App.ValidateFrom(form, schema);
    setForm({ ...form, error: {} });
    let error = {};
    isValidate = ValidateFrom.isValidate;
    error = ValidateFrom.error;
    setForm({ ...form, error: error });
    // console.log(error);
    /// check Coupon
    if (isValidate && App.service.isNullOrEmpty(ID)) {
      if (form.ButtonType === 0) {
        if (CouponList.length <= 0) {
          isValidate = false;
          setIndex(1);
          dispatch(
            setDialog({
              open: true,
              title: "Oop !",
              description: "Import Coupon !",
            })
          );
        }
        // else if (CouponList.length !== parseInt(form.CouponAamoth)) {
        //   isValidate = false;
        //   // console.log("CouponAamoth");
        //   dispatch(
        //     setDialog({
        //       open: true,
        //       title: "Oop !",
        //       description: "Coupon Amoth !",
        //     })
        //   );
        // }
      }
    } else {
      if (!isValidate) {
        let textError = "";
        // eslint-disable-next-line array-callback-return
        Object.keys(error).map((e) => {
          // console.log(e)
          textError = textError + e + ",";
        });
        console.log(textError);
        if (
          textError.includes("CampaignCode") ||
          textError.includes("CampaignName") ||
          textError.includes("StartDate") ||
          textError.includes("StartTime")
        ) {
          setIndex(0);
          console.log(0);
        } else if (
          textError.includes("Category") ||
          textError.includes("CouponTitle") ||
          textError.includes("CouponAamoth")
        ) {
          setIndex(1);
        }
      }
    }
    if (isValidate && App.service.isNullOrEmpty(ID)) {
      if (App.service.isNullOrEmpty(form.image0)) {
        dispatch(
          setDialog({
            open: true,
            title: "This is a save warning message !",
            description: "Please Select Cover Image ?",
          })
        );
        setIndex(1);
        isValidate = false;
      }
      if (form.Type !== 0 && form.Project.length === 0) {
        dispatch(
          setDialog({
            open: true,
            title: "This is a save warning message !",
            description: "Please Select Project ?",
          })
        );
        isValidate = false;
      }
      // if (isValidate) {
      //   if (form.Project.length > 0 && form.UnitNo.length === 0) {
      //     dispatch(
      //       setDialog({
      //         open: true,
      //         title: "This is a save warning message !",
      //         description: "Please Select Home Address ?",
      //       })
      //     );
      //     isValidate = false;
      //   }
      // }
    }
    return isValidate;
  };
  const save = async (IsDaft = false) => {
    if (validate()) {
      // console.log("save");
      dispatch(setLoading(true));
      let _save = await App.service.postHttp("/privilege", {
        ...form,
        id: ID,
        IsDaft: IsDaft,
        // Project:Project,
        CouponList: CouponList,
        isPhoto0: isPhoto0,
        isPhoto1: isPhoto1,
        isPhoto2: isPhoto2,
        isPhoto3: isPhoto3,
        isPhoto4: isPhoto4,
        isPrivilege: true,
      });
      if (_save.status) {
        if (_save.data.status) {
          enqueueSnackbar("This is a save success message!", {
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
          if (App.service.isNullOrEmpty(ID)) {
            window.history.replaceState(
              null,
              null,
              "/privilegeandservice/privilege/info/" + _save.data.id
            );
            setID(_save.data.id);
          }
          getData();
          setIsPhoto0(false);
          setIsPhoto1(false);
          setIsPhoto2(false);
          setIsPhoto3(false);
          setIsPhoto4(false);
          setIsModify(false);
        } else {
          if (_save.data.isDuplicated) {
            setIndex(0);
            let error = {};
            enqueueSnackbar("This is a save warning message!", {
              variant: "warning",
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
            App.service.lodash.map([..._save.data.Duplicated], (e) => {
              if (e === "CampaignCode") {
                error[e] = "Campaign Code Duplicated";
                enqueueSnackbar("Campaign Code Duplicated !", {
                  variant: "warning",
                  anchorOrigin: { horizontal: "right", vertical: "top" },
                });
              }
              if (e === "CampaignName") {
                error[e] = "Campaign Name Duplicated";
                enqueueSnackbar("Campaign Name Duplicated !", {
                  variant: "warning",
                  anchorOrigin: { horizontal: "right", vertical: "top" },
                });
              }
            });
            setForm({ ...form, error: error });
          } else {
            enqueueSnackbar("This is a save failed message!", {
              variant: "error",
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
          }
        }
      } else {
        enqueueSnackbar("This is a save failed message!", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
      dispatch(setLoading(false));
    }
  };
  const close = () => {
    if (!isModify) {
      navigate(-1);
    } else {
      dispatch(
        setDialog({
          open: true,
          title: "Edit Information !",
          description: "Want to save?",
          Callback: () => {
            save();
          },
          Close: () => {
            navigate(-1);
          },
        })
      );
    }
  };
  const getData = async () => {
    dispatch(setLoading(true));
    setisloading(true);
    // let partner = await App.service.getHttp("/partner/enum", dispatch);
    // if (partner.status) {
    //   if (partner.data.status) {
    //     setpartner([...partner.data.data]);
    //     // console.log([...partner.data.data]);
    //   }
    // }
    // let category = await App.service.getHttp("/category/enum/0", dispatch);
    // if (category.status) {
    //   if (category.data.status) {
    //     setcategory([...category.data.data]);
    //     // console.log([...category.data.data]);
    //   }
    // }
    // let unit = await App.service.getHttp("/notification/unit/enum", dispatch);
    // if (unit.status) {
    //   if (unit.data.status) {
    //     setunit([...unit.data.data]);
    //     // console.log([...Campaign.data.data]);
    //   }
    // }
    if (!App.service.isNullOrEmpty(id)) {
      setID(id);
      let item = await App.service.getHttp("/privilege/" + id, dispatch);
      if (item.status) {
        // if (Data.data.status) {
        //   setForm({ ...Data.data.Data, error: {} });
        //   setCouponList(Data.data.Data.CouponList);
        // }
        setForm({ ...item.Data, error: {} });
        setCouponList(item.Data.CouponList);
      }
    }
    setisloading(false);
    dispatch(setLoading(false));
  };
  React.useEffect(() => {
    // if (!App.service.isNullOrEmpty(id)) {
    getData();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Daft = () => {
    save(true);
  };
  React.useEffect(() => {
    setFooterAction(
      <App.FooterAction
        save={() => save()}
        close={close}
        Daft={App.service.isNullOrEmpty(ID) || form.IsDaft ? Daft : undefined}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isModify]);

  const Impost = (data) => {
    // console.log(data);
    let CoponCodeList = [];
    data
      .filter((e) => e.length > 0)
      // eslint-disable-next-line array-callback-return
      .map((e) => {
        CoponCodeList.push({
          CoponCode: e[0],
        });
      });
    setCouponList(CoponCodeList);
  };
  const ActionCoponCode = () => {
    // console.log("ActionCoponCode"
    // console.log(CouponList);
    App.getExportFileBlob({
      columns: Object.keys(CouponList[0]),
      Header: Object.keys(CouponList[0]),
      data: CouponList,
      fileName: "CouponList",
      SheetName: "CouponList",
    });
  };
  // console.log(" id " + id);
  return (
    !isloading && (
      <Box>
        <App.MainCard
          title={
            index === 0
              ? "Campaign Detail"
              : index === 1
              ? "Coupon Detail"
              : "Distribution"
          }
          isTab={true}
          Tab={() => (
            <Box display="flex" mb={1}>
              <Box
                p={1}
                style={{
                  width: "auto",
                  borderBottom:
                    "2px solid" + (index === 0 ? "#161E44" : "transparent"),
                }}
                onClick={() => {
                  setIndex(0);
                }}
              >
                <App.Label
                  text="Campaign Detail"
                  fontWeight={index === 0 ? "bold" : ""}
                  size={22}
                />
              </Box>
              <Box
                p={1}
                style={{
                  width: "auto",
                  borderBottom:
                    "2px solid" + (index === 1 ? "#161E44" : "transparent"),
                }}
                onClick={() => {
                  setIndex(1);
                }}
              >
                <App.Label
                  text="Coupon Detail"
                  fontWeight={index === 1 ? "bold" : ""}
                  size={22}
                />
              </Box>
              {/* <Box
                p={1}
                style={{
                  width: "auto",
                  borderBottom:
                    "2px solid" + (index === 2 ? "#161E44" : "transparent"),
                }}
                onClick={() => {
                  setIndex(2);
                }}
              >
                <App.Label
                  text="Distribution"
                  fontWeight={index === 2 ? "bold" : ""}
                  size={22}
                />
              </Box> */}
            </Box>
          )}
          toolBar={index === 1 ? true : false}
          isImpost={
            App.service.isNullOrEmpty(ID) && form.ButtonType === 0
              ? true
              : false
          }
          Impost={Impost}
          ImpostName={"Import Coupon"}
          isCoponCode={
            (CouponList.length > 0 || !App.service.isNullOrEmpty(ID)) &&
            form.ButtonType === 0
              ? true
              : false
          }
          ActionCoponCode={ActionCoponCode}
        >
          {index === 0 ? (
            <CampaignDetail
              form={form}
              onChange={onChange}
              partner={partner}
              ID={ID}
            />
          ) : index === 1 ? (
            <CouponDetail
              form={form}
              onChange={onChange}
              category={category}
              ID={ID}
            />
          ) : (
            <Distribution form={form} onChange={onChange} ID={ID} unit={unit} />
          )}
        </App.MainCard>
        {/* {index === 1 ? (
          <Box mt={1}>
            <App.MainCard title={"Condition"}>
              <Condition form={form} onChange={onChange} ID={ID} />
            </App.MainCard>
          </Box>
        ) : null} */}
      </Box>
    )
  );
};

export default PrivilegeInfo;
