import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Label from "../Label";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
const BasicCheckbox = (props) => {
  const { title, name, value, onChange, disabled } = props;
  const handleChange = (event) => {
    onChange({ name: name, value: event.target.checked });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormGroup>
        <FormControlLabel
          onChange={handleChange}
          disabled={disabled || false}
          control={<Checkbox checked={value} />}
          label={<Label text={title} size={18} color={"#161E44"} />}
        />
      </FormGroup>
    </Box>
  );
};
export default BasicCheckbox;
BasicCheckbox.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
